import React from "react";

import AppointmentsList from "../../containers/AppointmentsList/AppointmentsList";
import CustomModal from "../CustomModal/CustomModal";
import { styles } from "./AppointmentModal.style";

const AppointmentModal = ({ isAppointmentModal, setisAppointmentModal }) => {
  const columns = [
    {
      id: 1,
      value: "patientName",
      label: "Patient Name",
      maxWidth: "180px",
      isSortable: true,
    },

    {
      id: 2,
      value: "appointmentDate",
      label: "Appointment Date",
      isSortable: true,
      isDataTypeDate: true,
      maxWidth: "150px",
    },

    {
      id: 3,
      value: "startTime, endTime, timeZone",
      label: "Appointment Time",
      maxWidth: "150px",
      sortValue: "appointmentTime",
      isSortable: true,
      isCombinedColumn: true,
      formatter: (data) => {
        const startTime = data.startTime || "--";
        const endTime = data.endTime || "--";
        const timeZone = data.timeZone
          ? "(" + data.timeZone.split(" ")[0] + ")"
          : "";
        return `${startTime} - ${endTime} ${timeZone}`.trim();
      },
    },

    {
      id: 4,
      value: "providerName",
      label: "Provider Name",
      maxWidth: "180px",
      isSortable: true,
    },

    {
      id: 5,
      value: "officeName",
      label: "Office Name",
      maxWidth: "180px",
      isSortable: true,
    },
    {
      id: 6,
      value: "appointmentType",
      label: "Appointment Type",
      maxWidth: "150px",
      isSortable: true,
    },
    {
      id: 7,
      value: "consultationFee",
      label: "Fee Paid",
      maxWidth: "120px",
      minWidth: "70px",
      isDataTypeAmount: true,
      isSortable: false,
    },
    {
      id: 8,
      value: "fadPatientNotes",
      label: "Patient Appointment Notes",
      maxWidth: "200px",
      isSortable: false,
    },
    {
      id: 9,
      value: "appointmentStatus",
      label: "Appointment Status",
      maxWidth: "150px",
      isSortable: true,
    },
  ];
  return (
    <CustomModal
      fullWidth
      maxWidth={"lg"}
      openModal={isAppointmentModal}
      placeCloseBtnOnExtremeRight={true}
      omitCloseButton={false}
      includeCloseButton={true}
      isLargeModal={true}
      closeModalHandler={() => {
        setisAppointmentModal(false);
      }}
    >
      <AppointmentsList
        AppointmentColumns={columns}
        noHeader
        hideTitle
        customBoxContainer={styles.customBoxContainer}
        tableContainer={styles.tableContainer}
        customTableContainer={styles.customTableContainer}
        customSearchAndFilterStyle={styles.customSearchAndFilterStyle}
        isSmallFilter
        noParams
      />
    </CustomModal>
  );
};

export default AppointmentModal;

import {
    DISABLE_GREY,
    APP_PRIMARY_THEME_COLOR,
    GREY98,
    WHITE,
    BERN_RED,
  } from "../../consts/colors";
  
  export const styles = {
    buttonStyles: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      backgroundColor: APP_PRIMARY_THEME_COLOR,
      color: GREY98,
      height: "40px",
      width: "90%",
      textTransform: "capitalize",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: APP_PRIMARY_THEME_COLOR,
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    disabledButton: {
      backgroundColor: DISABLE_GREY,
      "&:hover": {
        backgroundColor: DISABLE_GREY,
      },
    },
    btnLoaderStyles: {
      marginLeft: "0.5rem",
      color: WHITE,
    },
    dangerButton: {
      backgroundColor: BERN_RED,
      "&:hover": {
        backgroundColor: BERN_RED,
      },
    },
  };
  
import { HOT_PURPLE, PINKISH_PURPLE, PURPLE_HAZE, WHITE } from "../../consts/colors";

export const styles={
    practiseBtn:{
        display: 'inline-block',
        marginBottom: '0',
        fontWeight: '600',
        textAlign: 'center',
        color:"white",
        verticalAlign: 'middle',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        touchAction: 'manipulation',
        cursor: 'pointer',
        transition: 'all .2s',
        backgroundImage: 'none',
        border: '2px solid transparent',
        whiteSpace: 'nowrap',
        padding: '3px 15px',
        fontSize: '12px',
        lineHeight: '1.78571',
        borderRadius: '20px',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        backgroundColor:`${HOT_PURPLE}`,
        height:"40px",
        width: "150px" 
      
    },
    customTableOuterContainer:{
        maxHeight:"calc(100vh - 330px)"
    },
    practiseHeader:{
        
            display: "flex",
            justifyContent:"flex-end",
            padding: "10px 15px",
          
    },
    commonBtnStyle: {
        width: "170px",
        marginLeft: "4px"
      },
      createButtonText: {
        fontSize: "16px",
        fontWeight: "500",
        color: WHITE,
        cursor: "pointer",
      },
    loaderStyle:{
        height: "calc(100vh - 280px)",

    }
    
}

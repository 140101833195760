import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import Base from "../../Layout/Base";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import CustomTable from "../../components/CustomTable/CustomTable";
import ErrorMessageBox from "../../components/ErrorComponent/ErrorComponent";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";
import useFetch from "../../api/common/useFetch";
import { getValidRowPerPage } from "../../utils/getValidRowPerPage";
import { handleRowPerPageChange } from "../../utils/handleRowPerPageChange";
import { setSearchParams } from "../../utils/handleSearchWithDebouncing";
import { urlService } from "../../utils/urlService";
import { DEFAULTSORT, DEFAULTSTATUS, columns } from "./providerListConfig";
import { GET_PROVIDER_LIST_FOR_ADMIN } from "../../api/apiEndpoints";
import { SORT_KEYS } from "../../consts/constants";
import { styles } from "./Provider.style";

const ProvidersList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [sorting, setSorting] = useState({ key: "firstName", type: 1 });
  const [timer, settimer] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(getValidRowPerPage());

  function getSortQueryParam({ key, type }) {
    const sortKeyMap = {
      id: "PROVIDER_ID",
      providerName: "PROVIDER_NAME",
      title: "PROVIDER_TITLE",
      firstName: "PROVIDER_FIRST_NAME",
      lastName: "PROVIDER_LAST_NAME",
      practiceName: "PRACTICE_NAME",
      activeStatus: "STATUS",
      role: "ROLE",
      address: "ADDRESS",
      city: "CITY",
      state: "STATE",
      country: "COUNTRY",
      zip: "ZIP",
      suite: "SUITE",
    };
    const sortTypeMap = {
      1: "_ASC",
      "-1": "_DESC",
    };
    const sortByValue = sortKeyMap[key] + sortTypeMap[type];
    return {
      sortBy: sortByValue,
    };
  }

  const getQueryParams = ({ sortby, status }) => {
    return {
      sortBy: sortby ? sortby : DEFAULTSORT,
      status: status ? status : DEFAULTSTATUS,
    };
  };
  const toggleSorting = (key, type) => {
    setCurrentPage(1);
    urlService.setQueryStringValue("pageNumber", 1);
    let sortby;
    if (type == SORT_KEYS.ASC && sorting.key === key && sorting.type == 1) {
      sortby = getSortQueryParam({})?.sortBy;
      fetchData({
        queryParamsObject: getQueryParams({ sortby, status: filter }),
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      setSorting({});
    } else if (
      type == SORT_KEYS.DESC &&
      sorting.key === key &&
      sorting.type == -1
    ) {
      sortby = getSortQueryParam({})?.sortBy;
      fetchData({
        queryParamsObject: getQueryParams({ sortby, status: filter }),
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });

      setSorting({});
    } else if (type == SORT_KEYS.ASC) {
      sortby = getSortQueryParam({ type: 1, key })?.sortBy;
      fetchData({
        queryParamsObject: getQueryParams({ sortby, status: filter }),
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      setSorting({ type: 1, key });
    } else if (type == SORT_KEYS.DESC) {
      sortby = getSortQueryParam({ type: -1, key })?.sortBy;
      fetchData({
        queryParamsObject: getQueryParams({ sortby, status: filter }),
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      setSorting({ type: -1, key });
    } else {
      setSorting({});
    }
    if (sortby) {
      urlService.setQueryStringValue("sortBy", sortby);
    } else {
      urlService.removeParam("sortBy");
    }
  };

  const { data, isLoading, fetchData, headers, error } = useFetch({
    url: GET_PROVIDER_LIST_FOR_ADMIN,
    otherOptions: {
      skipApiCallOnMount: true,
    },
    callback:()=>{
      setCurrentPage(1)
    }
  });

  let sortby = getSortQueryParam(sorting)?.sortBy;

  const total = headers?.totalcount;

  const handlePageChange = (newPage) => {
    const totalpages = Math.ceil(total / rowsPerPage);

    if (newPage >= 1 && newPage <= totalpages) {
      fetchData({
        queryParamsObject: getQueryParams({ sortby, status: filter }),

        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: newPage,
            recPerPage: rowsPerPage,
          },
        },
      });
      setCurrentPage(newPage);
      urlService.setQueryStringValue("pageNumber", newPage);
    }
  };

  const handleChangeText = (event) => {
    setCurrentPage(1);
    setSearchQuery(event.target.value);
    setSearchParams(event.target.value);
    urlService.setQueryStringValue("pageNumber", 1);
    if (timer) {
      clearTimeout(timer);
      settimer(null);
    }
    let timerId = setTimeout(
      () => {
        fetchData({
          queryParamsObject: getQueryParams({ sortby, status: filter }),
          otherApiOptions: {
            headers: {
              searchText: event.target.value,
              pageNumber: 1,
              recPerPage: rowsPerPage,
            },
          },
        });
      },

      1200
    );
    settimer(timerId);
  };

  function parseSortQueryParam(sortByValue) {
    const invertedSortKeyMap = {
      PROVIDER_ID: "id",
      PROVIDER_NAME: "providerName",
      PROVIDER_TITLE: "title",
      PROVIDER_FIRST_NAME: "firstName",
      PROVIDER_LAST_NAME: "lastName",
      PRACTICE_NAME: "practiceName",
      STATUS: "activeStatus",
      ROLE: "role",
      ADDRESS: "address",
      CITY: "city",
      STATE: "state",
      COUNTRY: "country",
      ZIP: "zip",
      SUITE: "suite",
    };

    const invertedSortTypeMap = {
      ASC: 1,
      DESC: -1,
    };

    const sortByValueArray = sortByValue.split("_");
    const sortColumn = sortByValueArray
      .slice(0, sortByValueArray.length - 1)
      .join("_");
    const sortDirection = sortByValueArray[sortByValueArray.length - 1];

    const value = invertedSortKeyMap[sortColumn] || null;
    const type = invertedSortTypeMap[sortDirection] || null;

    return {
      key: value,
      type: type,
    };
  }

  const filterparams = (newFilter) => {
    const map = {
      all: "All",
      active: "Active",
      inactive: "In-Active",
    };
    return map[newFilter];
  };

  const getFilterParams = (status) => {
    const Reversemap = {
      All: "all",
      Active: "active",
      "In-Active": "inactive",
    };

    return Reversemap[status];
  };

  const changeFilter = (title, newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
    urlService.setQueryStringValue("status", filterparams(newFilter));
    urlService.setQueryStringValue("pageNumber", 1)
    fetchData({
      queryParamsObject: getQueryParams({ sortby, status: newFilter }),
      otherApiOptions: {
        headers: {
          searchText: searchQuery,
          pageNumber: 1,
          recPerPage: rowsPerPage,
        },
      },
    });
  };

  const handleRowChange = (rowCount) => {
    handleRowPerPageChange({
      rowCount,
      fetchData: () => fetchData({
        queryParamsObject: getQueryParams({ sortby, status: filter }),
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowCount,
          },
        },
      }),
      setRowsPerPage,
      setCurrentPage
    });
  };

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    const page = urlService.getQueryStringValue("pageNumber") || 1;
    const search = urlService.getQueryStringValue("search") || "";
    const sortby = urlService.getQueryStringValue("sortBy") || "";
    const filter = urlService.getQueryStringValue("status") || "";
    if (page) {
      setCurrentPage(+page);
    }
    if (search) {
      setSearchQuery(search);
    }
    if (sortby) {
      setSorting(parseSortQueryParam(sortby));
    }
    if (filter) {
      setFilter(getFilterParams(filter));
    }
    fetchData({
      queryParamsObject: getQueryParams({ sortby, status: filter }),
      otherApiOptions: {
        headers: {
          searchText: search,
          pageNumber: page,
          recPerPage: rowsPerPage,
        },
      },
    });
  };

  const filterOptions = [
    {
      id: 1,
      filterKey: "Provider Status",
      title: "Provider Status",
      handleOptionChange: changeFilter,
      content: [
        {
          label: "All",
          value: "all",
        },
        {
          label: "Active",
          value: "active",
        },

        {
          label: "In-Active",
          value: "inactive",
        },
      ],
    },
  ];

  return (
    <Base>
      <Box sx={styles.right}>
        <SearchAndFilter
          searchPlaceHolder={" Search Care Pulse Providers"}
          headerTitle={"Care Pulse Providers"}
          handleChangeText={handleChangeText}
          changeFilter={changeFilter}
          search={searchQuery}
          selectedValue={{ "Provider Status": filter }}
          filterOptions={filterOptions}
          isFilterRequired={true}
          defaultSelected={"all"}
        />
        {isLoading && !error ? (
          <Box sx={styles.loaderContainer}>
            <CustomLoader customHeight={styles.loaderStyles} />
          </Box>
        ) : error ? (
          <Box sx={styles.errorOuter}>
            <ErrorMessageBox
              errorText={error}
              errorHeading={"Data could not load"}
              customContainerStyles={{ display: "flex", alignItems: "center" }}
            />
          </Box>
        ) : (
          <CustomTable
            columns={columns}
            data={data || []}
            toggleSorting={toggleSorting}
            sorting={sorting}
            pagesInfo={{
              currentPage: currentPage,
              total,
              rowsperPage: rowsPerPage,
              handleRowPerPage: handleRowChange,
            }}
            handlePageChange={handlePageChange}
          />
        )}
      </Box>
    </Base>
  );
};

export default ProvidersList;

import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "./PatientBlockModal.style";
import moment from "moment";
export const PatientBlockModal = ({ data }) => {
  return (
    <Box sx={styles.outerContainer}>
      <Box sx={styles.headerContainer}>
        <Box sx={styles.headerStyle}>
          <Typography sx={styles.headerText}>Status: Blocked</Typography>
        </Box>
      </Box>
      <Box sx={styles.flexContainer}>
        <Box sx={styles.text}>{`Reason`}</Box>
        <Typography sx={styles.text}>
          {`${data?.blockedReason ? data.blockedReason : "  --"}`}
        </Typography>
      </Box>
      <Box display={styles.flexContainer}>
        <Box sx={styles.text}>{`Blocked By:`}</Box>
        <Box sx={styles.text}>
          {`${data?.blockedBy ? data.blockedBy : "  --"}`}
        </Box>
      </Box>
      <Box sx={styles.flexContainer}>
        <Box sx={styles.text}>{`Blocked Date :`}</Box>
        <Box sx={styles.text}>
          {`${
            data?.blockedAt
              ? moment(data.blockedAt).format("MMMM DD YYYY")
              : "  --"
          }`}
        </Box>
      </Box>
    </Box>
  );
};

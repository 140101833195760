import React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { modalCommonStyles } from "../../assests/commonStyles";

import { styles } from "./BlockUserModal.style";

const BlockUserModal = ({
  handleBlock,
  loading,
  reasonText,
  setReasonText,
}) => {
  return (
    <Box sx={styles.blockContainer}>
      {loading ? (
        <Box sx={styles.loadingContainer}>
          <CircularProgress sx={modalCommonStyles.loader} />
        </Box>
      ) : (
        <Box>
          <Typography sx={styles.header}>
            Are you sure you want to block this patient?
          </Typography>
          <Box sx={styles.contentContainer}>
            <Typography sx={styles.reasonHeader}>
              Please specify reason for blocking this user's Care Pulse access
            </Typography>
            <Typography
              component={"textarea"}
              onChange={(event) => setReasonText(event.target.value)}
              value={reasonText}
              sx={styles.textArea}
              maxLength={350}
            />
          </Box>
          <Box sx={styles.blockBtnContainer}>
            <Button
              onClick={() => {
                handleBlock(reasonText);
              }}
              sx={styles.blockBtn}
            >
              Block
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BlockUserModal;

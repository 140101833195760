import { PatientBlockModal } from "../../components/PatientBlockModal/PatientBlockModal";
import { capitalizeFirstWord } from "../../utils/capitalizeWord";

export const columns = [
  {
    id: 1,
    value: "isAccessBlocked",
    label: "Status",
    isDot: true,
    isSortable: false,
    isPatientModal: true,
    ToolTipModal: PatientBlockModal,
    ToolTipMode: "GreenMode",
    formatter: function (obj) {
      let value = obj[this.value];
      let deleted = obj["deleted"];
      return deleted ? "warning" : value === true ? "danger" : "success";
    },
    isTooltip: true,
    isDarkMode: true,
    toolTipformatter: function (obj) {
      let value = obj[this.value];
      return value === false ? "unblocked" : "blocked";
    },
  },
  {
    id: 2,
    value: "firstName",
    label: "Patient First Name",
    minWidth: "150px",
    maxWidth: "180px",
    isSortable: true,
  },
  {
    id: 3,
    value: "lastName",
    label: "Patient Last Name",
    minWidth: "150px",
    maxWidth: "180px",
    isSortable: true,
  },
  {
    id: 4,
    value: "created_at",
    label: "Member Since",
    minWidth: "130px",
    isSortable: true,
    isDataTypeDate: true,
  },
  {
    id: 5,
    value: "email",
    label: "Patient Email",
    minWidth: "220px",
    isSortable: false,
    noWordWrap: true,
  },
  {
    id: 6,
    value: "phoneNumber",
    label: "Phone Number",
    minWidth: "150px",
    maxWidth: "180px",
    isSortable: false,
    noWordWrap: true,
  },
  {
    id: 7,
    value: "gender",
    label: "Gender",
    minWidth: "150px",
    isSortable: false,
    noWordWrap: true,
  },

  {
    id: 8,
    value: "dob",
    label: "Date of Birth",
    minWidth: "150px",
    isSortable: true,
    isDataTypeDate: true,
  },
  {
    id: 9,
    value: "address",
    label: "Street Address",
    minWidth: "200px",
    maxWidth: "250px",
    isSortable: false,
    isEllipsis: true,
    displayEyeIcon: true,
  },
  {
    id: 10,
    value: "city",
    label: "City",
    minWidth: "150px",
    maxWidth: "180px",
    isSortable: true,
    formatter: function (obj) {
      let value = obj[this.value];
      return capitalizeFirstWord(value);
    },
  },
  {
    id: 11,
    value: "state",
    label: "State",
    minWidth: "160px",
    maxWidth: "180px",
    isSortable: true,
    formatter: function (obj) {
      let value = obj[this.value];
      return capitalizeFirstWord(value);
    },
  },
  {
    id: 12,
    value: "zip",
    label: "Zip",
    minWidth: "150px",
    isSortable: true,
    formatter: function (obj) {
      let value = obj[this.value];
      return capitalizeFirstWord(value);
    },
  },
  {
    id: 13,
    value: "country",
    label: "Country",
    minWidth: "120px",
    maxWidth: "180px",
    isSortable: true,
    formatter: function (obj) {
      let value = obj[this.value];
      return capitalizeFirstWord(value);
    },
  },
];

export const Defaultsort = "CREATED_AT_DESC";

import React from "react";
import CustomPrompt from "../CustomPrompt/CustomPrompt";

const UnBlockUserModal = ({setunBlockedUserModal,loading,handleUnBlock,unblockedUserModal}) => {
  
  return (
    <CustomPrompt
          fullWidth
          maxWidth={"sm"}
          open={unblockedUserModal}
          showLoader={loading}
          heading={"Confirm"}
          descriptionLineOne={
            `Are you sure you want to unblock this user?`}
          buttonOneText={
          "Unblock"
          }
          hideModalImage
          onButtonOneClick={handleUnBlock}
          closeModalHandler={()=>setunBlockedUserModal(false)
        }
        />
      )}


export default UnBlockUserModal;

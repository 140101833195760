import { useEffect } from "react";

const useHandleEscapeKeyEvent = ({ onEscapePress }) => {
  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, false);
    return () => {
      window.removeEventListener("keyup", handleKeyUp, false);
    };
  }, []);

  const handleKeyUp = (e) => {
    const keys = {
      27: () => {
        e.preventDefault();
        onEscapePress && onEscapePress();
      },
    };
    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  };
};

export default useHandleEscapeKeyEvent;

import { BLACK, CRAYOLA, PAYNEGREY } from "../../consts/colors";

export const styles = {
  blockContainer: {
    padding: "20px 20px",
    minHeight: "320px",
    minWidth: "330px",
  },
  loadingContainer: {
    height: "300px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  header: {
    padding: "10px 0px",
    fontSize: "18px",
    color: CRAYOLA,
    fontWeight: "600",
  },
  contentContainer: {
    padding: "15px 0px",
  },
  reasonHeader: {
    padding: "10px 0px",
  },
  textArea: {
    width: "100%",
    minHeight: "6em",
    padding: "10px",
    outline: "none",
    "&:focus": {
      borderColor: BLACK,
      borderWidth: "1px",
      borderStyle: "solid",
    },
  },
  blockBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  blockBtn: {
    backgroundColor: PAYNEGREY,
    border: "2px solid #c7c7c7",
    borderRadius: "2em",
    padding: "10px 20px",
    color: "white",
    "&:hover": {
      backgroundColor: PAYNEGREY,
    },
  },
};

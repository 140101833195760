import moment from "moment";

const checkIfString = (str) => {
  if (typeof str === "string") return str.trim();
  return str;
};

const handleTableValues = ({
  data,
  isDataTypeAmount,
  isDataTypeDate,
  isEmptyValue,
  dateFormat
}) => {
  const exactData = checkIfString(data);
  if (!exactData && !isEmptyValue) return "--";
  if (!exactData && isEmptyValue) return "";
  if (isDataTypeAmount) return `$${exactData}`;
  if (isDataTypeDate) {
    const format = dateFormat || "MM/DD/YYYY";
    return moment(new Date(exactData)).format(format);
  }
  return exactData;
};

export default handleTableValues;

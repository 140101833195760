import React from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import Pagination from "../Pagination/Pagination";
import handleTableValues from "../../utils/handleTableValues";
import { SORT_KEYS } from "../../consts/constants";
import { HOT_PURPLE } from "../../consts/colors";
import { styles } from "./CustomTable.style";

const CustomTable = ({
  columns,
  customTableContainer,
  customTableOuterContainer,
  data,
  toggleSorting,
  sorting,
  pagesInfo,
  handlePageChange,
  handleRowClick,
  customPaperStyle,
  customBoxContainer,
  tableContainer
}) => {
  function splitLabelWithLineBreak(label) {
    const parts = label.split("&");
    if (parts.length === 2) {
      return (
        <>
          {parts[0].trim()} &amp;
          <br />
          {parts[1].trim()}
        </>
      );
    }
    return label;
  }
  const Active = ({ status, column, data }) => {
    if (column.isDot == false) {
      return status;
    }
    if (status == "success") {
      return (
        <Box sx={styles.activeContainer}>
          <Box sx={styles.sucess}></Box>
        </Box>
      );
    } else if (status == "danger") {
      return (
        <ConditionalTooltipWrapper
          Modal={column?.ToolTipModal}
          data={data}
          column={column}
        >
          <Box sx={styles.activeContainer}>
            <Box sx={styles.danger}></Box>
          </Box>
        </ConditionalTooltipWrapper>
      );
    } else if (status === "warning") {
      return (
        <Box sx={styles.activeContainer}>
          <Box sx={styles.warning}></Box>
        </Box>
      );
    }
  };
  const ConditionalTooltipWrapper = ({ children, data, Modal, column }) => {
    if (Modal) {
      return (
        <TooltipWrapper
          {...(column.ToolTipMode ? { ToolTipMode: true } : { darkMode: true })}
          title={<Modal data={data} />}
          placement="bottom"
          arrow
        >
          {children}
        </TooltipWrapper>
      );
    }

    return children;
  };

  const Actions = ({ column, data }) => {
    let { actions } = column;
    return (
      <Box sx={styles.actionsContainer}>
        {actions.length &&
          actions.map(
            ({ src, alt, onClick, MuiIcon, isShow, name, bgColor }, idx) => {
              const showAction = isShow ? isShow(data) : true;
              if (showAction) {
                return (
                  <TooltipWrapper
                    key={idx}
                    backgroundColor={bgColor || HOT_PURPLE}
                    darkMode={true}
                    title={name}
                    placement="top"
                    arrow
                  >
                    {MuiIcon ? (
                      <MuiIcon
                        sx={styles.actionIcon}
                        onClick={() => onClick(data)}
                      />
                    ) : (
                      <Typography
                        key={idx}
                        component={"img"}
                        sx={styles.actionItems}
                        src={src}
                        alt={alt}
                        onClick={() => {
                          onClick(data);
                        }}
                      />
                    )}
                  </TooltipWrapper>
                );
              }
            }
          )}
      </Box>
    );
  };

  function getTableCell(column, value, idx, data) {
    switch (column.label) {
      case "Status":
        return <Active status={value} column={column} data={data} />;
      case "Actions":
        return <Actions column={column} data={data} />;
      default:
        if (column.displayEyeIcon && value !== "--") {
          return (
            <Box sx={styles.largerCellContainer}>
              <Typography sx={styles.largerText}>{value}</Typography>
              <VisibilityIcon
                sx={styles.actionItems}
                onClick={(e) =>
                  column.onEyeIconClick && column.onEyeIconClick(data, e)
                }
              />
            </Box>
          );
        }
        if (column.isDataTypeImage) {
          const imageUrl = !!column.imageData
            ? column.imageData(data)
            : data[column.value] || column.defaultImage;
          return imageUrl ? (
            <Typography
              component="img"
              src={imageUrl}
              alt={column.label}
              sx={styles.imageStyle}
            />
          ) : (
            <Typography>--</Typography>
          );
        }
        return column?.isEllipsis && value !== "--" ? (
          <TooltipWrapper title={value} placement={"bottom"}>
            <Typography
              sx={styles.ellipsis}
            >
              {value}
            </Typography>
          </TooltipWrapper>
        ) : (
          value
        );
    }
  }

  return (
    <Box sx={{...customBoxContainer}}>
    <Paper sx={{ ...styles.tableParentContainer, ...customTableContainer ,...{}}}>
      <TableContainer
        sx={{ ...styles.tableOuterContainer, ...customTableOuterContainer ,...tableContainer}}
      >
        <Table
          sx={{ ...styles.tableContainer, ...customTableOuterContainer ,...{}}}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow style={styles.tableHeader}>
              {columns.map((column) => {
                const sortValue = column?.sortValue
                  ? column?.sortValue
                  : column.value;
                const isActiveSort = sorting?.key === sortValue;
                return (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    sx={{ ...styles.tableHeader, minWidth: column.minWidth }}
                  >
                    {column.isSortable ? (
                      <Box sx={styles.outerFilterContainer}>
                        <Typography sx={styles.labelStyle}>
                          {splitLabelWithLineBreak(column.label)}
                        </Typography>
                        <Box sx={styles.headerFilter}>
                          <Box
                            onClick={() =>
                              toggleSorting(sortValue, SORT_KEYS.ASC)
                            }
                          >
                            <ArrowUpward
                              sx={{
                                ...styles.arrow,
                                ...(isActiveSort &&
                                  sorting?.type === 1 &&
                                  styles.greenArrow),
                              }}
                            />
                          </Box>
                          <Box
                            onClick={() =>
                              toggleSorting(sortValue, SORT_KEYS.DESC)
                            }
                          >
                            <ArrowDownward
                              sx={{
                                ...styles.arrow,
                                ...(isActiveSort &&
                                  sorting?.type === -1 &&
                                  styles.greenArrow),
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Typography sx={styles.labelStyle}>
                        {splitLabelWithLineBreak(column.label)}
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data?.length &&
              data.map((row, idx) => {
                return (
                  <TableRow
                    onClick={() => {                
                      handleRowClick && handleRowClick(row)             
                    }}
                    role="checkbox"
                    tabIndex={-1}
                    key={idx}
                    sx={{
                      ...styles.tableRow,
                      ...(idx % 2 ? styles.evenRow : {}),
                      ...handleRowClick && styles.pointer
                    }}
                  >
                    {columns.map((column) => {
                      const value = column?.formatter
                        ? column.formatter(row)
                        : handleTableValues({
                            data: row[column.value],
                            isDataTypeDate: column.isDataTypeDate,
                            isDataTypeAmount: column.isDataTypeAmount,
                            dateFormat: column.dateFormat,
                          });
                      return (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{
                            ...styles.tableCell,
                            maxWidth: column?.maxWidth,
                            ...(typeof column?.customTableCellStyle ===
                            "function"
                              ? column.customTableCellStyle(row)
                              : column.customTableCellStyle || {}),
                            ...(column.isTextCapitalize ? styles.capitalizeText : {}),
                          }}
                        >
                          {
                            <Box
                              sx={
                                column.isEllipsis
                                  ? {
                                      maxWidth: column?.maxWidth,
                                      ...styles.ellipsis,
                                    }
                                  : {}
                              }
                            >
                              {getTableCell(column, value, idx, row)}
                            </Box>
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {!data?.length && (
              <TableRow sx={styles.emptyTableContainer}>
                <TableCell colSpan={columns?.length}>No data to show</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination pagesInfo={pagesInfo} handlePageChange={handlePageChange} />
    </Paper>
    </Box>
  );
};
CustomTable.defaultProps = {
  customBoxContainer: {},
  tableContainer: {},
  customTableContainer: {},
};

export default CustomTable;

import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "./LogsModal.style";

const LogsModal = ({ logModalData }) => {
  return (
    <Box
      sx={styles.logContainer}
    >
      <Box style={styles.logHeader}>Logs</Box>
      <Box
        style={styles.contentContainer}
      >
        <Box style={styles.content}>
          { logModalData.length>0 ? logModalData.map((data) => (
            <Typography>{data}</Typography>
          )):
         <Typography>{"No logs to show"}</Typography>
        }
        </Box>
      </Box>
    </Box>
  );
};

export default LogsModal;

import React, { useEffect, useState } from "react";
import Base from "../../Layout/Base";
import { Box, Snackbar } from "@mui/material";

import AppointmentModal from "../../components/AppointmentModal/AppointmentModal";
import BlockUserModal from "../../components/BlockUserModal/BlockUserModal";
import CustomModal from "../../components/CustomModal/CustomModal";
import CustomPrompt from "../../components/CustomPrompt/CustomPrompt";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import CustomTable from "../../components/CustomTable/CustomTable";
import { Defaultsort, columns } from "./PatientsListConfig";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LogsModal from "../../components/LogsModal/LogsModal";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";
import UnBlockUserModal from "../../components/UnBlockUserModal/UnBlockUserModal";
import useBlockUser from "../../utils/apiService/Hooks/useBlockUser";
import { handleRowPerPageChange } from "../../utils/handleRowPerPageChange";
import { getValidRowPerPage } from "../../utils/getValidRowPerPage";
import { setSearchParams } from "../../utils/handleSearchWithDebouncing";
import useFetch from "../../api/common/useFetch";
import { urlService } from "../../utils/urlService";
import { GET_PATIENT_LIST_FOR_ADMIN } from "../../api/apiEndpoints";
import { SORT_KEYS } from "../../consts/constants";
import blockImage from "../../img/block.svg";
import logsImage from "../../img/logs.svg";
import unblock from "../../img/unlock.png";
import appointementImage from "../../img/Appointement.svg";
import { commonStyles } from "../../assests/commonStyles";
import { styles } from "./PatientsList.style";

const PatientList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ type: -1, key: "created_at" });
  const [timer, settimer] = useState();
  const [logModalData, setlogModalData] = useState([]);
  const [blockedUserModal, setBlockedUserModal] = useState(false);
  const [unblockedUserModal, setunBlockedUserModal] = useState(false);
  const [selectedBlockUser, setSelectedBlockUserModal] = useState(null);
  const [selectedUnBlockUser, setSelectedUnBlockUserModal] = useState(null);
  const [reasonText, setReasonText] = useState("");
  const [retryloading, setretryloading] = useState(false);
  const [isAppointmentModal, setisAppointmentModal] = useState(false);
  const [userAppointement, setUserAppointement] = useState(null);
  const [detailedAdress, setDetailedAdress] = useState("");
  const [isLogModalOpened, setisLogModalOpened] = useState(false);
  const [sucesstoast, setSuccessToast] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(getValidRowPerPage());

  function getSortQueryParam({ key, type }) {
    const sortKeyMap = {
      firstName: "NAME",
      lastName: "NAME",
      activeStatus: "STATUS",
      address: "ADDRESS",
      city: "CITY",
      state: "STATE",
      country: "COUNTRY",
      zip: "ZIP",
      created_at: "CREATED_AT",
      gender: "GENDER",
      address: "ADDRESS",
      dob: "DOB",
    };
    const sortTypeMap = {
      1: "_ASC",
      "-1": "_DESC",
    };
    const sortByValue = sortKeyMap[key] + sortTypeMap[type];
    return {
      sortBy: sortByValue,
    };
  }

  const toggleSorting = (key, type) => {
    let sortby;
    setCurrentPage(1);
    urlService.setQueryStringValue("pageNumber", 1);
    if (type == SORT_KEYS.ASC && sorting.key === key && sorting.type == 1) {
      sortby = getSortQueryParam({})?.sortBy;
      const obj = getQueryParams({ sortby });
      fetchData({
        queryParamsObject: obj,
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      setSorting({});
    } else if (
      type == SORT_KEYS.DESC &&
      sorting.key === key &&
      sorting.type == -1
    ) {
      sortby = getSortQueryParam({})?.sortBy;
      const obj = getQueryParams({ sortby });
      fetchData({
        queryParamsObject: obj,
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      setSorting({});
    } else if (type == SORT_KEYS.ASC) {
      sortby = getSortQueryParam({ type: 1, key })?.sortBy;
      const obj = getQueryParams({ sortby });
      fetchData({
        queryParamsObject: obj,
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      setSorting({ type: 1, key });
    } else if (type == SORT_KEYS.DESC) {
      sortby = getSortQueryParam({ type: -1, key })?.sortBy;
      const obj = getQueryParams({ sortby });
      fetchData({
        queryParamsObject: obj,
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      setSorting({ type: -1, key });
    } else {
      setSorting({});
    }
    if (sortby) {
      urlService.setQueryStringValue("sortBy", sortby);
    } else {
      urlService.removeParam("sortBy");
    }
  };

  const actions = [
    {
      id: 1,
      name: "Block",
      onClick: (data) => {
        setBlockedUserModal(true);
        setSelectedBlockUserModal(data);
      },
      alt: "Block",
      modal: false,
      src: blockImage,
      muiIcon: "",
      isShow: (data) => {
        return data["isAccessBlocked"] === false;
      },
    },
    {
      id: 1,
      name: "Unblock",
      onClick: (data) => {
        setunBlockedUserModal(true);
        setSelectedUnBlockUserModal(data);
      },
      alt: "Unblock",
      modal: false,
      src: unblock,
      muiIcon: "",
      isShow: (data) => {
        return data["isAccessBlocked"] === true;
      },
    },

    {
      id: 2,
      name: "Logs",
      onClick: (data) => {
        setisLogModalOpened(true);
        setlogModalData(data.logs);
      },
      alt: "logs",
      modal: true,
      src: logsImage,
      muiIcon: "",
    },
    {
      id: 3,
      name: "Appointments",
      onClick: (data) => {
        setisAppointmentModal(true);
        setUserAppointement(data);
      },
      alt: "Appointments",
      modal: false,
      src: appointementImage,
      muiIcon: "",
    },
  ];

  const { data, isLoading, fetchData, headers, error, setData, setError } =
    useFetch({
      url: GET_PATIENT_LIST_FOR_ADMIN,
      otherOptions: {
        skipApiCallOnMount: true,
        headers: {
          recPerPage: rowsPerPage,
        },
      },

      callback: () => {
        setCurrentPage(1);
      },
    });

  let sortby = getSortQueryParam(sorting)?.sortBy;

  const total = headers?.totalcount;

  const handlePageChange = (newPage) => {
    const totalpages = Math.ceil(total / rowsPerPage);
    if (newPage >= 1 && newPage <= totalpages) {
      fetchData({
        queryParamsObject: getQueryParams({ sortby }),
        otherApiOptions: {
          headers: {
            searchText: searchQuery,
            pageNumber: newPage,
            recPerPage: rowsPerPage,
          },
        },
      });
      setCurrentPage(newPage);
      urlService.setQueryStringValue("pageNumber", newPage);
    }
  };

  const getQueryParams = ({ sortby }) => {
    return { sortBy: sortby ? sortby : Defaultsort };
  };

  const handleChangeText = (event) => {
    setCurrentPage(1);
    setSearchQuery(event.target.value);
    setSearchParams(event.target.value);
    urlService.setQueryStringValue("pageNumber", 1);
    if (timer) {
      clearTimeout(timer);
      settimer(null);
    }
    let timerId = setTimeout(
      () => {
        fetchData({
          queryParamsObject: getQueryParams({ sortby }),
          otherApiOptions: {
            headers: {
              searchText: event.target.value,
              pageNumber: 1,
              recPerPage: rowsPerPage,
            },
          },
        });
      },

      1200
    );
    settimer(timerId);
  };

  function parseSortQueryParam(sortByValue) {
    const invertedSortKeyMap = {
      NAME: "firstName",
      STATUS: "activeStatus",
      ADDRESS: "address",
      CITY: "city",
      STATE: "state",
      COUNTRY: "country",
      ZIP: "zip",
      CREATED_AT_ASC: "created_at",
      GENDER: "gender",
      DOB: "dob",
    };

    const invertedSortTypeMap = {
      ASC: 1,
      DESC: -1,
    };

    const sortByValueArray = sortByValue.split("_");
    const sortColumn = sortByValueArray
      .slice(0, sortByValueArray.length - 1)
      .join("_");
    const sortDirection = sortByValueArray[sortByValueArray.length - 1];

    const value = invertedSortKeyMap[sortColumn] || null;
    const type = invertedSortTypeMap[sortDirection] || null;

    return {
      key: value,
      type: type,
    };
  }

  const handleRowChange = (rowCount) => {
    handleRowPerPageChange({
      rowCount,
      fetchData: () =>
        fetchData({
          queryParamsObject: getQueryParams({ sortby }),
          otherApiOptions: {
            headers: {
              searchText: searchQuery,
              pageNumber: 1,
              recPerPage: rowCount,
            },
          },
        }),
      setRowsPerPage,
      setCurrentPage,
    });
  };

  useEffect(() => {
    const page = urlService.getQueryStringValue("pageNumber") || 1;
    const search = urlService.getQueryStringValue("search") || "";
    const sortby = urlService.getQueryStringValue("sortBy") || "";
    if (page) {
      setCurrentPage(+page);
    }
    if (search) {
      setSearchQuery(search);
    }
    if (sortby) {
      setSorting(parseSortQueryParam(sortby));
    }
    fetchData({
      queryParamsObject: getQueryParams({ sortby }),
      otherApiOptions: {
        headers: {
          searchText: search,
          pageNumber: page,
          Recperpage: rowsPerPage,
        },
      },
    });
  }, []);
  const {
    loading,
    errorWhileuserBlockStattus,
    blockUserHandler,
    setErrorWhileuserBlockStattus,
    type,
  } = useBlockUser();

  const handleLogModalData = (data) => {
    setlogModalData(data);
  };

  const retry = async () => {
    try {
      setretryloading(true);
      if (type == "block") {
        await handleBlock();
      } else if (type == "unblock") {
        await handleUnBlock();
      }
    } catch (error) {
    } finally {
      setretryloading(false);
    }
  };

  const handleBlock = async (reasonText) => {
    try {
      const id = selectedBlockUser.id;

      const payload = {
        appUserId: id,
        blockedReason: reasonText,
      };
      await blockUserHandler(payload, true, handleBlockUser);
    } catch (error) {
    } finally {
      setBlockedUserModal(false);
    }
  };

  const handleBlockUser = async () => {
    const id = selectedBlockUser.id;
    setData((prevData) => {
      const index = prevData?.data?.findIndex((item) => item.id === id);
      if (index === -1) return prevData;

      const newData = prevData;
      newData.data[index] = {
        ...newData?.data?.[index],
        isAccessBlocked: true,
      };
      return newData;
    });
    setBlockedUserModal(false);
    setSuccessToast("User blocked sucessfully");
    setTimeout(() => {
      setSuccessToast("");
    }, 2000);
  };

  const handleUnBlockUser = async () => {
    const id = selectedUnBlockUser.id;

    setData((prevData) => {
      const index = prevData?.data?.findIndex((item) => item.id === id);
      if (index === -1) return prevData;

      const newData = prevData;
      newData.data[index] = {
        ...newData?.data?.[index],
        isAccessBlocked: false,
      };
      return newData;
    });
    setSuccessToast("User unblocked sucessfully");
    setTimeout(() => {
      setSuccessToast("");
    }, 2000);

    setSelectedUnBlockUserModal(null);
  };

  const handleUnBlock = async () => {
    const id = selectedUnBlockUser.id;

    const payload = {
      appUserId: id,
      blockedReason: reasonText,
    };
    await blockUserHandler(payload, false, handleUnBlockUser);
    setunBlockedUserModal(false);
  };

  const updateColumnsWithFunctions = () => {
    columns.map((col) => {
      if (col["value"] === "address") {
        col.onEyeIconClick = (data) => {
          setDetailedAdress(data["address"]);
        };
      }
    });
  };
  const closeAdressModal = () => {
    setDetailedAdress("");
  };
  updateColumnsWithFunctions();
  return (
    <Base customStyles={commonStyles.tableContainer}>
      <Box>
        <Snackbar
          ContentProps={{
            sx: styles.snackBarStyle,
          }}
          open={sucesstoast.length > 0}
          autoHideDuration={5000}
          onClose={() => setSuccessToast("")}
          message={sucesstoast}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <CustomModal
          maxWidth={"md"}
          modalContainerCustomStyles={styles.centered}
          includeCloseButton={true}
          omitCloseButton={false}
          openModal={errorWhileuserBlockStattus}
          closeModalHandler={() => {
            setErrorWhileuserBlockStattus("");
            setBlockedUserModal(false);
            setError("");
          }}
        >
          <ErrorComponent
            customContainerStyles={styles.full}
            errorHeading={"Error"}
            errorText={errorWhileuserBlockStattus}
            onRetry={() => {
              retry();
            }}
            omitCloseButton
          />
        </CustomModal>
        <CustomPrompt
          fullWidth
          maxWidth={"sm"}
          open={retryloading}
          showLoader={retryloading}
        />
        <CustomModal
          maxWidth={"md"}
          modalContainerCustomStyles={styles.centered}
          includeCloseButton={true}
          omitCloseButton={false}
          openModal={isLogModalOpened}
          closeModalHandler={() => {
            handleLogModalData([]);
            setisLogModalOpened(false);
          }}
        >
          <LogsModal logModalData={logModalData} />
        </CustomModal>
        <CustomModal
          fullWidth
          maxWidth={"sm"}
          modalContainerCustomStyles={styles.centered}
          includeCloseButton={true}
          omitCloseButton={false}
          openModal={blockedUserModal}
          closeModalHandler={() => {
            setBlockedUserModal(false);
            setSelectedBlockUserModal(null);
            setErrorWhileuserBlockStattus("");
          }}
          placeCloseBtnOnExtremeRight
        >
          <BlockUserModal
            selectedBlockUser={selectedBlockUser}
            setData={setData}
            setSelectedBlockUserModal={setSelectedBlockUserModal}
            setBlockedUserModal={setBlockedUserModal}
            handleBlock={handleBlock}
            loading={loading}
            reasonText={reasonText}
            setReasonText={setReasonText}
          />
        </CustomModal>
        <CustomPrompt
          open={detailedAdress.length > 0}
          fullWidth
          omitCloseButton
          closeModalHandler={closeAdressModal}
          descriptionLineOne={detailedAdress}
          heading={"Address"}
          hideModalImage
          buttonOneText={"close"}
          onButtonOneClick={closeAdressModal}
        />
        <UnBlockUserModal
          setunBlockedUserModal={setunBlockedUserModal}
          loading={loading}
          handleUnBlock={handleUnBlock}
          unblockedUserModal={unblockedUserModal}
        />
        {isAppointmentModal && userAppointement && (
          <AppointmentModal
            isAppointmentModal={isAppointmentModal}
            setisAppointmentModal={setisAppointmentModal}
            userAppointement={userAppointement}
            setUserAppointement={setUserAppointement}
          />
        )}
        <SearchAndFilter
          searchPlaceHolder={" Search Care Pulse Patients"}
          headerTitle={"Care Pulse Patients"}
          handleChangeText={handleChangeText}
          search={searchQuery}
        />
        {isLoading && (
          <CustomLoader customHeight={commonStyles.loaderContainer} />
        )}
        {!error && !isLoading && (
          <CustomTable
            columns={[
              ...columns,
              {
                id: 16,
                value: "actions",
                label: "Actions",
                minWidth: 130,
                actions,
              },
            ]}
            data={data || []}
            toggleSorting={toggleSorting}
            sorting={sorting}
            pagesInfo={{
              currentPage,
              total,
              rowsperPage: rowsPerPage,
              handleRowPerPage: handleRowChange,
            }}
            handlePageChange={handlePageChange}
          />
        )}
      </Box>

      {error && (
        <Box sx={commonStyles.errorContainer}>
          <ErrorComponent
            errorHeading={"Error"}
            errorText={error}
            omitCloseButton
          />
        </Box>
      )}
    </Base>
  );
};

export default PatientList;

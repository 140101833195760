export const styles = {
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "0 14px",
      overflowX: "auto",
    },
    loaderContainer: {
      height: "calc(100vh - 200px)",
    },
    errorContainer: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    customTabelStyle:{
      maxHeight:"50vh"
    },
    customOuterStyle:{
      maxHeight:"60vh",
    },
    customSearchAndFilterStyle:{
      marginTop:"40px"
    },
    customBoxContainer:{
      maxHeight:"85vh"
  },
    tableContainer:{
      maxHeight:"40vh"
  },
    customTableContainer:{
      maxHeight:"50vh",overflowY:"hidden"
  }


  };
  
import navigations from "./routeNames";

export const permissionNames = {
  deleteOffices: "deleteOffices",
  deletePractices: "deletePractices",
  deleteSaaS: "deleteSaaS",
  deleteUsers: "deleteUsers",
  fad: "fadTabs",
  practices: "practices",
  saas: "saasAdmin",
  users: "users",
};

const checkAuthorisation = ({ permissions, requestedRoute }) => {
  const doesContainRoute = (route) => {
    return requestedRoute?.toLowerCase().includes(route?.toLowerCase());
  };

  if (permissions) {
    if (
      doesContainRoute(navigations.APPOINTMENTS) ||
      doesContainRoute(navigations.PATIENTS) ||
      doesContainRoute(navigations.PROVIDERS) ||
      doesContainRoute(navigations.REVIEWS) ||
      doesContainRoute(navigations.REVENUE)
    ) {
      return permissions?.includes(permissionNames.fad);
    }
    if (doesContainRoute(navigations.PRACTISES)) {
      return permissions?.includes(permissionNames.practices);
    }
    if (doesContainRoute(navigations.SAAS)) {
      return permissions?.includes(permissionNames.saas);
    }
    if (doesContainRoute(navigations.USERS)) {
      return permissions?.includes(permissionNames.users);
    }
    return false;
  }
  return false;
};

export default checkAuthorisation;

export const styles = {
  right: {
    padding: "0px 14px",
    flex: 1,
  },
  loaderContainer: {
    width: "100%",
    height: "calc(100vh - 200px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderStyles: {
    height: "50vh",
  },
  errorOuter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 200px)",
  },
  customErrorContainerStyles: {
    display: "flex",
    alignItems: "center",
  },
};

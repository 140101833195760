import { urlService } from "./urlService";

export const handleRowPerPageChange = ({
  rowCount,
  fetchData,
  setRowsPerPage,
  setCurrentPage,
  omitUrlService,
}) => {
  setRowsPerPage(rowCount);
  setCurrentPage(1);
  fetchData();
  if (!omitUrlService) {
    urlService.setQueryStringValue("pageNumber", 1);
    urlService.setQueryStringValue("rowPerPage", rowCount);
  }
};

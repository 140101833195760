import { debounce } from "lodash";
import { urlService } from "./urlService";

const debouncedGetResult = debounce(
  (
    getResult,
    pageNumber,
    setCurrentPage,
    hideQueryParams
  ) => {
    getResult();
    if (!hideQueryParams) {
      urlService.setQueryStringValue("pageNumber", pageNumber);
    }
    setCurrentPage(pageNumber);
  },
  1000
);

const handleSearchWithDebouncing = ({
  searchText,
  prevSearchText,
  setSearchText,
  getResult,
  setCurrentPage,
  hideQueryParams = false
}) => {
  setSearchText(searchText);
  if (!hideQueryParams) {
    if (searchText.trim()) {
      urlService.setQueryStringValue("search", searchText);
    } else {
      urlService.removeParam("search");
    }
  }
  if (
    searchText.trim() !== "" ||
    (searchText === "" && prevSearchText.trim() !== "")
  ) {
    debouncedGetResult(
      getResult,
      1,
      setCurrentPage,
      hideQueryParams
    );
  }
};

export const setSearchParams=(searchText)=>{
    if (searchText.trim()) {
      urlService.setQueryStringValue("search", searchText);
    } else {
      urlService.removeParam("search");
    }
  }



export default handleSearchWithDebouncing;

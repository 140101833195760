import { BUDGREEN, MISTY_ROSE, VENETION_RED } from "../../consts/colors";

export const styles={

outerContainer:{

    minHeight:"100px",
    backgroundColor:"white",
    display:"flex",
    flexDirection:"column",
    // alignItems:"center",
    justifyContent:"space-between",
    padding:"10px",
    gap:"10px",
    boxShadow:"#00000040",
    
},
container:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    fontSize:"12px",
    color:"white",
    height:"100%",
    backgroubdColor:"black"
},
text:{
    fontSize:"12px",
    
},
headerStyle:{
    padding:"6px 12px 6px  12px",
    borderRadius:"60px",
    border:`1px solid  ${VENETION_RED}`,
    backgroundColor:MISTY_ROSE
},
headerText:{
    fontWeight:"500",
    fontSize:"12px",
    lineHeight:"20px",
},
flexContainer:{
    display:"flex",
    gap:"10px"
},
headerContainer:{
    width:"100%",display:"flex",justifyContent:"center",
    marginBottom:"10px"
}

}
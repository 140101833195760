import { APP_PRIMARY_THEME_COLOR, WHITE } from "../../consts/colors";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "60px",
  },
  loginSubContainer: {
    width: "100%",
    padding: "0px 42px",
    "@media (max-width: 600px)": {
      padding: "0px 20px",
    },
  },
  logoStyles: {
    width: "auto",
    height: "65px",
  },
  loginFormContainer: {
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  labelAndInputBox: {
    marginBottom: "1rem",
  },
  labelInputProp: {
    color: WHITE,
  },
  showPasswordContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  forgetPasswordText: {
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "white",
    "@media (min-width: 2000px)": {
      fontSize: "14px",
    },
    cursor: "pointer",
  },
  loginButton: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: WHITE,
    color: APP_PRIMARY_THEME_COLOR,
    height: "40px",
    textTransform: "capitalize",
    borderRadius: "20px",
    width: "127px",
    padding: "12px",
    "@media (max-width: 600px)": {
      width: "127px",
    },
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  loginButtonContainer: {
    marginTop: "3rem",
    display: "flex",
    justifyContent: "end",
    marginBottom: "2rem",
    "@media (max-width : 600px)": {
      marginBottom: "1rem",
    },
  },
  forgetPasswordContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  errorMessageContainer: {
    position: "relative",
    width: "100%",
  },
};

const dev = {
  url: {
    API_URL: "https://staging-api.novadonticspms.com",
  },
};
const prod = {
  url: {
    API_URL: "https://api.novadonticspms.com",
  },
};
export const config = process.env.REACT_APP_ENVIRONMENT === "production" ? prod : dev;

export const API_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const AUTH_KEY = "auth";

export const COPY_RIGHT_TEXT =
  "© 2021-2025 \u00A0 Care Pulse,\u00A0 LLC. All Rights Reserved. www.mycarepulse.com";
export const TRY_AGAIN = "Try Again";
export const SORT_KEYS = {
  ASC: "ASC",
  DESC: "DESC",
};

export const REC_PER_PAGE = 25;
export const CANCEL_GET_API_CALL = "Cancel get api";
export const SLIDE_BY = 300;

export const ZOOM_CONSTANT = {
  MAX_ZOOM: 3,
  MIN_ZOOM: 1,
  ZOOM_STEP: 0.1,
};

export const FILE_MAX_SIZE = 5000000;

export const UPLOAD_LIMITS = {
  logo: 1,
  documents: 10,
};

export const SAAS_DATA_SAVE_SUCCESS = "Data saved successfully.";

export const OTHERCARDS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const AMERICANEXPRESS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const EXPIRYDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const CVC = [/[0-9]/, /\d/, /\d/, /\d/];
export const ROW_PER_PAGE_OPTIONS = [25, 50, 100];
export const RESET_PASSWORD_TEXT="In order to protect your account, make sure your password :"
export const MIN_PASSWORD_LENGTH_TEXT="is longer than 7 characters"
export const PASSWORD_LETTER_CONSTRAINT="should contain at least one lower case letter (a-z), one upper case letter (A-Z) and one number"
export const PASSWORD_CONSTRAINTS_FAILED_MESSAGE="Your password should contain minimum 7 characters, at least one lower case letter (a-z), one upper case letters (A-Z) and one number"
export const PASSWORD_RESET_SUCCESS_MESSAGE="Your password has been updated successfully"

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import Base from "../../Layout/Base";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import CustomPrompt from "../../components/CustomPrompt/CustomPrompt";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import CustomTable from "../../components/CustomTable/CustomTable";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";
import useFetch from "../../api/common/useFetch";
import handleSearchWithDebouncing from "../../utils/handleSearchWithDebouncing";
import {
  getCurrentSortFilter,
  updateSortFilter,
} from "../../utils/getCurrentSortFilter";
import { getValidRowPerPage } from "../../utils/getValidRowPerPage";
import { handleRowPerPageChange } from "../../utils/handleRowPerPageChange";
import { urlService } from "../../utils/urlService";
import {
  appointmentListColumns,
  appointmentTableFilterMap,
} from "./AppointmentListConstants";
import { GET_APPOINTMENT_LIST_FOR_ADMIN } from "../../api/apiEndpoints";
import { REC_PER_PAGE } from "../../consts/constants";
import { commonStyles } from "../../assests/commonStyles";

const AppointmentsList = ({
  AppointmentColumns,
  hideTitle,
  customBoxContainer,
  tableContainer,
  customTableContainer,
  noParams,
  customSearchAndFilterStyle,
  isSmallFilter,
}) => {
  const [currentPage, setCurrentPage] = useState(
    noParams ? 1 : urlService.getQueryStringValue("pageNumber") || 1
  );
  const [searchText, setSearchText] = useState(
    noParams ? "" : urlService.getQueryStringValue("search") || ""
  );
  const [sorting, setSorting] = useState({ type: -1, key: "appointmentDate" });
  const [sortBy, setSortBy] = useState(
    noParams ? "APPOINTMENT_DATE_DESC" : urlService.getQueryStringValue("sortBy") || "APPOINTMENT_DATE_DESC"
  );
  const [selectedFilter, setSelectedFilter] = useState({
    appointmentStatusFilter:
      urlService.getQueryStringValue("appointmentStatusFilter") || "all",
    appointmentType: urlService.getQueryStringValue("appointmentType") || "all",
  });
  const [patientNotesDescription, setPatientNotesDescription] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(noParams ? REC_PER_PAGE : getValidRowPerPage());

  const {
    data: appointmentsList,
    fetchData: fetchAppointmentList,
    headers,
    isLoading: isGettingAppointmentsList,
    error: errorGettingAppointmentList,
  } = useFetch({
    url: `${GET_APPOINTMENT_LIST_FOR_ADMIN}?sortBy=${sortBy}&appointmentStatusFilter=${selectedFilter.appointmentStatusFilter}&appointmentType=${selectedFilter.appointmentType}`,
    apiOptions: {
      headers: {
        pageNumber: currentPage,
        searchText: searchText,
        Recperpage: rowsPerPage,
      },
    },
    callback:()=>{
      setCurrentPage(1)
    }
  });

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilter((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [filterKey]: value,
      };
      setCurrentPage(1);

      if (!noParams) {
        urlService.setQueryStringValue("pageNumber", 1);
        urlService.setQueryStringValue(filterKey, value);
      }
      fetchAppointmentList({
        queryParamsObject: {
          ...updatedFilters,
          sortBy: sortBy,
        },
        otherApiOptions: {
          headers: {
            searchText: searchText,
            pageNumber: 1,
            recPerPage: rowsPerPage,
          },
        },
      });
      return updatedFilters;
    });
  };

  const appointmentTableFilter = [
    {
      title: "Appointment Type",
      content: [
        { label: "All", value: "all" },
        { label: "In-Office", value: "in-office" },
        { label: "Teledental", value: "teledental" },
      ],
      filterKey: "appointmentType",
      handleOptionChange: handleFilterChange,
    },
    {
      title: "Appointment Status",
      content: [
        { label: "All", value: "all" },
        { label: "Upcoming", value: "upcoming" },
        { label: "Past", value: "past" },
        { label: "Canceled", value: "canceled" },
      ],
      filterKey: "appointmentStatusFilter",
      handleOptionChange: handleFilterChange,
    },
  ];

  const handlePageChange = (pageNumber) => {
    const totalpages = Math.ceil(headers?.totalcount / rowsPerPage);
    if (pageNumber >= 1 && pageNumber <= totalpages) {
      fetchAppointmentList({
        otherApiOptions: {
          headers: {
            searchText: searchText,
            pageNumber: pageNumber,
            recPerPage: rowsPerPage,
          },
        },
      });
      setCurrentPage(pageNumber);
      if (!noParams) urlService.setQueryStringValue("pageNumber", pageNumber);
    }
  };

  const handleSortChange = (sortingFilter, currentSortType) => {
    const isFilterSelectedSameAsPrevious =
      `${Object.keys(appointmentTableFilterMap).find(
        (key) => appointmentTableFilterMap[key] === sortingFilter
      )}_${currentSortType}` === sortBy;
    updateSortFilter({
      sortingFilter: sortingFilter,
      currentSortType: currentSortType,
      defaultSortKey: "appointmentDate",
      filterTableMap: appointmentTableFilterMap,
      fetchListFunction: fetchAppointmentList,
      searchText: searchText,
      currentPage: currentPage,
      recPerPage: rowsPerPage,
      setSortBy: setSortBy,
      setSorting: setSorting,
      setCurrentPage: setCurrentPage,
      hideQueryParams: noParams,
      resetSortBy: isFilterSelectedSameAsPrevious,
    });
  };

  const handleChangeText = (event) => {
    handleSearchWithDebouncing({
      searchText: event.target.value,
      hideQueryParams: noParams,
      prevSearchText: searchText,
      setSearchText: setSearchText,
      getResult: () =>
        fetchAppointmentList({
          otherApiOptions: {
            headers: {
              searchText: event.target.value,
              pageNumber: 1,
              recPerPage: rowsPerPage,
            },
          },
        }),
      sortBy: sortBy,
      setCurrentPage: setCurrentPage,
    });
  };

  const appointmentListColumnWithEyeAction = (
    AppointmentColumns || appointmentListColumns
  ).map((column) => {
    if (column.displayEyeIcon) {
      return {
        ...column,
        onEyeIconClick: (data) =>
          setPatientNotesDescription(data?.fadPatientNotes),
      };
    }
    return column;
  });

  const handleRowChange = (rowCount) => {
    handleRowPerPageChange({
      rowCount,
      fetchData: () =>
        fetchAppointmentList({
          otherApiOptions: {
            headers: {
              searchText: searchText,
              pageNumber: 1,
              recPerPage: rowCount,
            },
          },
        }),
      setRowsPerPage,
      setCurrentPage,
      omitUrlService: noParams
    });
  };

  useEffect(() => {
    const page = noParams ? 1 : urlService.getQueryStringValue("pageNumber") || 1;
    const search = noParams ? searchText : urlService.getQueryStringValue("search") || searchText;
    const sortByFilter = noParams ? sortBy : urlService.getQueryStringValue("sortBy") || sortBy;
    if (page) {
      setCurrentPage(+page);
    }
    if (search) {
      setSearchText(search);
    }
    if (sortByFilter) {
      setSorting(
        getCurrentSortFilter({
          sortBy: sortByFilter,
          defaultSortKey: "appointmentDate",
          filterTableMap: appointmentTableFilterMap,
        })
      );
    }
  }, []);
  return (
    <Base customStyles={commonStyles.tableContainer}>
      <Box>
        <SearchAndFilter
          {...(!hideTitle ? { headerTitle: "Care Pulse Appointments" } : {})}
          search={searchText}
          searchPlaceHolder={"Search Appointments"}
          handleChangeText={handleChangeText}
          filterOptions={appointmentTableFilter}
          selectedValue={selectedFilter}
          defaultSelected={"all"}
          isFilterRequired
          {...(isSmallFilter ? { isSmallFilter: true } : {})}
          {...(customSearchAndFilterStyle
            ? { customSearchAndFilterStyle }
            : {})}
        />
        {isGettingAppointmentsList && (
          <CustomLoader customHeight={commonStyles.loaderContainer} />
        )}
        {!isGettingAppointmentsList && !errorGettingAppointmentList && (
          <CustomTable
          {...{ customBoxContainer, customTableContainer, tableContainer }}
          columns={appointmentListColumnWithEyeAction}
            data={appointmentsList}
            pagesInfo={{
              currentPage: currentPage,
              total: +headers?.totalcount,
              rowsperPage: rowsPerPage,
              handleRowPerPage: handleRowChange,
            }}
            handlePageChange={handlePageChange}
            toggleSorting={handleSortChange}
            sorting={sorting}
          />
        )}
        {!!patientNotesDescription && (
          <CustomPrompt
            open={!!patientNotesDescription}
            heading={"Patient Appointment Notes"}
            descriptionLineOne={patientNotesDescription}
            buttonOneText={"Close"}
            onButtonOneClick={() => setPatientNotesDescription("")}
            omitCloseButton
            fullWidth
            maxWidth="sm"
            hideModalImage
          />
        )}
      </Box>
      {!isGettingAppointmentsList && !!errorGettingAppointmentList && (
        <Box sx={commonStyles.errorContainer}>
          <ErrorComponent
            errorHeading={"Error"}
            errorText={errorGettingAppointmentList}
            omitCloseButton
          />
        </Box>
      )}
    </Base>
  );
};

export default AppointmentsList;

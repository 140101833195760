const navigations = {
  ROOT: "/",
  APPOINTMENTS: "/appointments",
  CREATE_SAAS: "/saas/create-saas",
  FORGOT_PASSWORD: "/forgot-password",
  LOGIN: "/login",
  PROVIDERS: "/providers",
  PATIENTS: "/patients",
  REVENUE: "/revenue",
  REVIEWS: "/reviews",
  PRACTISE:"/practices/:id",
  PRACTISES:"/practices",
  SAAS: "/saas",
  OUT_SOURCED: "*",
  CREATE_PRACTISE:"/practices/create",
  EDIT_PRACTISE:"/practices/:id/edit",
  USERS: "/users",
  CREATE_USER:"/users/create",
  RESET_PASSWORD:'/reset/:id'
};

export default navigations;

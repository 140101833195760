import useNavigateScreen from "../../CustomHooks/useNavigateScreen";
import { useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import { sideBarIcons } from "./sideBarConfig";
import TooltipWrapper from "../../components/TooltipWrapper/TooltipWrapper";
import { styles } from "./Sidebar.style";

export default function Sidebar() {
  const { navigateScreen } = useNavigateScreen();
  const location = useLocation();
  return (
    <>
      <Drawer sx={styles.drawerStyle} variant="permanent" anchor="left">
        <Toolbar />
        <List>
          {sideBarIcons.map(
            ({ img, navigateTo, name, displaySeparator }, index) => {
              return (
                <Box key={index}>
                  <ListItem key={index} disablePadding>
                    <TooltipWrapper title={name} placement="right">
                      <ListItemButton
                        sx={{
                          ...(location.pathname.includes(navigateTo)
                            ? styles.listItemButtonSelected
                            : styles.listItemButton),
                        }}
                        onClick={() => navigateScreen(navigateTo)}
                      >
                        <ListItemIcon sx={styles.listItemIcon}>
                          <Typography
                            component={"img"}
                            src={img}
                            sx={styles.sideBarIcon}
                            alt={name}
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </TooltipWrapper>
                  </ListItem>
                  <Box
                    sx={{ ...(displaySeparator && styles.displaySeparator) }}
                  />
                </Box>
              );
            }
          )}
        </List>
      </Drawer>
    </>
  );
}

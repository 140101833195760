import React, { useContext, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import AccessDenied from "../../components/AccessDenied/AccessDenied";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import { UserContext } from "../../globalContext/user/userProvider";
import useGettingUserInfo from "../../api/user/useGettingUserInfo";
import checkAuthorisation from "../authorisation";
import navigations from "../routeNames";
import getToken from "../../auth/getToken";

const PrivateRoute = (props) => {
  const location = useLocation();

  const isUserLoggedIn = getToken();
  const [userState] = useContext(UserContext);

  const { cancelTokenRef, getUserDetails } = useGettingUserInfo();

  useEffect(() => {
    const apiCancelToken = cancelTokenRef?.current;
    getUserDetails();
    return () => {
      apiCancelToken?.cancel("Component unmounted");
    };
  }, []);

  if (!isUserLoggedIn) {
    return <Redirect to={navigations.LOGIN} />;
  }
  if (userState?.isGettingUserDetails) {
    return <CustomLoader />;
  }
  if (
    userState?.userInfo?.data &&
    !checkAuthorisation({
      permissions: userState?.userInfo?.data?.CarePulseAdmin?.accessTo,
      requestedRoute: location.pathname,
    })
  ) {
    return <AccessDenied />;
  }
  return props.component;
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
};

export default PrivateRoute;

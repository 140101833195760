import { APP_THEME_THIRTEEN, GREY95, WHITE, WHITE_SMOKE } from "../../consts/colors";

export const styles = {
  appbarStyle: {
    border: "1px 1px 1px 0px",
    backgroundColor: APP_THEME_THIRTEEN,
  },
  toolBarStyle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    border: "1px 1px 1px 0px",
    borderBlockColor: GREY95,
    padding: "20px 0px",  
    "@media (max-width : 600px)": {
    padding:"20px 10px"
    },   
  },
  imagelogo: {
    height: "40px",
    width: "auto",
    cursor: "pointer",
  },
  rightContainer: {
    display: "flex",
    gap: "12px",
    alignItems: "center"
  },
  userInfoContainer: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    flexDirection: "row",
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "40px",
  },
  welcomeText: {
    color: WHITE,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "600",
    textAlign: "right",
    "@media (max-width : 600px)": {
      fontSize:"12px"
      },
  },
  userType: {
    color: WHITE_SMOKE,
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "500",
    textAlign: "right",
    "@media (max-width : 600px)": {
      fontSize:"10px"
      },
  },
  loaderStyles: {
    height: "24px"
  },
  loaderIconStyles: {
    height: "24px !important",
    width: "24px !important",
    color: WHITE
  },
  logoutContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutBtn: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
};


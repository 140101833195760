import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import useHandleEscapeKeyEvent from "../../CustomHooks/useHandleEscapeKeyEvent";
import { styles } from "./CustomModalStyles";

const CustomModal = ({
  children,
  closeModalHandler,
  fullWidth,
  includeCloseButton,
  maxWidth,
  modalContainerCustomStyles,
  omitCloseButton,
  openModal,
  placeCloseBtnOnExtremeRight,
  isLargeModal,
}) => {
  const onEscapePress = () => {
    if (omitCloseButton) {
      return;
    }
    closeModalHandler && closeModalHandler();
  };

  useHandleEscapeKeyEvent({ onEscapePress });

  return (
    <>
      {openModal && (
        <Dialog
          maxWidth={maxWidth ? maxWidth : "md"}
          sx={{
            ...styles.modal,
            ...(isLargeModal && styles.largeModalStyle),
            ...modalContainerCustomStyles,
          }}
          fullWidth={fullWidth}
          open={openModal}
        >
          {includeCloseButton ? (
            <Box sx={styles.buttonContainer}>
              <Button
                onClick={closeModalHandler}
                sx={{
                  ...(placeCloseBtnOnExtremeRight
                    ? styles.btnOnExtremeRightStyles
                    : {}),
                }}
              >
                <ClearIcon sx={styles.closeIcon} />
              </Button>
            </Box>
          ) : (
            ""
          )}
          {children}
        </Dialog>
      )}
    </>
  );
};

CustomModal.propTypes = {
  children: PropTypes.node,
  closeModalHandler: PropTypes.func,
  fullWidth: PropTypes.bool,
  includeCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  modalContainerCustomStyles: PropTypes.object,
  omitCloseButton: PropTypes.bool,
  openModal: PropTypes.bool,
  placeCloseBtnOnExtremeRight: PropTypes.bool,
  isLargeModal: PropTypes.bool,
};

export default CustomModal;

import React, { Suspense } from "react";
import { useContext } from "react";
import {
  Route,
  Router,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";

import CustomLoader from "./components/CustomLoader/CustomLoader";
import DefaultRoute from "./routes/Components/DefaultRoute";
import Header from "./components/Header/Header";
import Login from "./containers/Login/Login";
import ProvidersList from "./containers/ProvidersList/ProvidersList";
import PatientList from "./containers/PatientsList/PatientList";
import PractisesList from "./containers/PractisesList/PractisesList";
import PrivateRoute from "./routes/Components/PrivateRoute";
import PublicRoute from "./routes/Components/PublicRoute";
import Sidebar from "./containers/Sidebar/Sidebar";
import { AuthContext } from "./globalContext/auth/authProvider";
import { UserContext } from "./globalContext/user/userProvider";
import navigations from "./routes/routeNames";

const ForgotPassword = React.lazy(() =>
  import("./containers/ForgotPassword/ForgotPassword")
);
const AppointmentsList = React.lazy(() =>
  import("./containers/AppointmentsList/AppointmentsList")
);
const ReviewList = React.lazy(() =>
  import("./containers/ReviewList/ReviewList")
);
const SaaSList = React.lazy(() => import("./containers/SaaSList/SaaSList"));
const RevenueList = React.lazy(() =>
  import("./containers/RevenueList/RevenueList")
);
const CreateSaaS = React.lazy(() =>
  import("./containers/CreateSaaS/CreateSaaS")
);
const UsersList = React.lazy(() => import("./containers/UsersList/UsersList"));
const CreateUsers = React.lazy(() =>
  import("./containers/CreateUsers/CreateUsers")
);
const CreatePractise = React.lazy(() =>
  import("./containers/CreatePractise/CreatePractise")
);
const Practice = React.lazy(() => import("./containers/Practice/Practice"));
const ResetPassword = React.lazy(() =>
  import("./containers/ResetPassword/ResetPassword")
);

function App({ history }) {
  const [authState] = useContext(AuthContext);
  const [userState] = useContext(UserContext);

  console.log("ci-cd testing");

  return (
    <Router history={history}>
      {!userState?.isGettingUserDetails && !!authState.auth && <Header />}
      <main className="main">
        {!!authState.auth && !userState?.isGettingUserDetails && <Sidebar />}
        <Suspense fallback={<CustomLoader />}>
          <Switch>
            <Route path={navigations.ROOT} exact>
              <DefaultRoute />
            </Route>
            <Route path={navigations.LOGIN} exact>
              <PublicRoute component={<Login />} />
            </Route>
            <Route path={navigations.FORGOT_PASSWORD} exact>
              <PublicRoute component={<ForgotPassword />} />
            </Route>
            <Route path={navigations.RESET_PASSWORD} exact>
              <PublicRoute component={<ResetPassword/>} />
            </Route>
            <Route path={navigations.PROVIDERS} exact>
              <PrivateRoute component={<ProvidersList />} />
            </Route>
            <Route path={navigations.PATIENTS} exact>
              <PrivateRoute component={<PatientList />} />
            </Route>
            <Route path={navigations.REVIEWS} exact>
              <PrivateRoute component={<ReviewList />} />
            </Route>
            <Route path={navigations.APPOINTMENTS} exact>
              <PrivateRoute component={<AppointmentsList />} />
            </Route>
            <Route path={navigations.REVENUE} exact>
              <PrivateRoute component={<RevenueList />} />
            </Route>
            <Route path={navigations.PRACTISES} exact>
              <PrivateRoute component={<PractisesList />} />
            </Route>
            <Route path={navigations.CREATE_PRACTISE} exact>
              <PrivateRoute component={<CreatePractise />} />
            </Route>
            <Route path={navigations.PRACTISE} exact>
              <PrivateRoute component={<Practice />} />
            </Route>
            <Route path={navigations.SAAS} exact>
              <PrivateRoute component={<SaaSList />} />
            </Route>
            <Route path={navigations.CREATE_SAAS} exact>
              <PrivateRoute component={<CreateSaaS />} />
            </Route>
            <Route path={navigations.USERS} exact>
              <PrivateRoute component={<UsersList />} />
            </Route>
            <Route path={navigations.CREATE_USER} exact>
              <PrivateRoute component={<CreateUsers />} />
            </Route>
            <Route path={navigations.OUT_SOURCED} exact>
              <DefaultRoute />
            </Route>
          </Switch>
        </Suspense>
      </main>
    </Router>
  );
}

export default App;

import { useEffect, useState } from "react";

import useAxiosInstance from "../../../Axios/hooks/useAxiosInstance";
import { API_STATUS } from "../../../consts/constants";
import { API_VERSION_NUMBER } from "../../../api/apiEndpoints";
import { SUCCESS_STATUS } from "../../../api/statusCodes";

const useBlockUser = () => {
  const [userBlockStatus, setuserBlockStatus] = useState(API_STATUS.IDLE);
  const [errorWhileuserBlockStattus, setErrorWhileuserBlockStattus] =
    useState("");
  const [type, setType] = useState("");

  const { axiosInstance } = useAxiosInstance();

  const blockUserHandler = async (payload, consent, callback) => {
    if (consent) {
      setType("block");
    } else {
      setType("unblock");
    }
    try {
      setuserBlockStatus(API_STATUS.LOADING);
      errorWhileuserBlockStattus && setErrorWhileuserBlockStattus("");
      const res = await axiosInstance.put(
        `${API_VERSION_NUMBER}/${`blockFadAccess?flag=${consent}`}`,
        payload
      );
      if (res.status === SUCCESS_STATUS) {
        setuserBlockStatus(API_STATUS.SUCCESS);
        callback && callback();
        return;
      }
      setuserBlockStatus(API_STATUS.ERROR);
    } catch (err) {
      setuserBlockStatus(API_STATUS.ERROR);

      if (err.response?.data?.message) {
        setErrorWhileuserBlockStattus(err.response?.data?.message);
        return;
      }
      if (consent == true) {
        setErrorWhileuserBlockStattus(
          "Failed to block this patient at the moment. Please try again later."
        );
      } else {
        setErrorWhileuserBlockStattus(
          "Failed to unblock this patient at the moment. Please try again later."
        );
      }
    }
  };
  const loading = userBlockStatus === API_STATUS.LOADING;
  const isSuccess = userBlockStatus === API_STATUS.SUCCESS;
  const isError = userBlockStatus === API_STATUS.ERROR;
  useEffect(() => {
    return () => {
      setuserBlockStatus(API_STATUS.IDLE);
    };
  }, []);

  return {
    loading,
    isSuccess,
    isError,
    errorWhileuserBlockStattus,
    blockUserHandler,
    userBlockStatus,
    setErrorWhileuserBlockStattus,
    setuserBlockStatus,
    type,
  };
};
export default useBlockUser;

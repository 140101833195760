import React from "react";
import PropTypes from "prop-types";
import { Box, InputBase } from "@mui/material";
import BackButton from "../BackButton/BackButton";
import FilterMenu from "../FilterMenu/FilterMenu";

import SearchIcon from "@mui/icons-material/Search";
import { styles } from "./SearchAndFilter.style";

const SearchAndFilter = ({
  defaultSelected,
  handleChangeText,
  search,
  isFilterRequired,
  searchPlaceHolder,
  headerTitle,
  filterOptions,
  selectedValue,
  isSmallFilter,
  customSearchAndFilterStyle,
  hideSearchBox,
  customToolbar,
  isBackButton,
  onBackButtonClick,
}) => {
  return (
    <Box style={{ ...styles.headerContainer, ...customSearchAndFilterStyle }}>
      <Box position="sticky" sx={{ ...styles.headerBox }}>
        <Box sx={{ ...styles.toolbarStyle, ...customToolbar }}>
          <Box sx={styles.backHeaderContainer}>
            {isBackButton && (
              <BackButton handleClick={onBackButtonClick}
           />             
            )}
            {headerTitle && <Box sx={styles.heading}>{headerTitle}</Box>}
          </Box>
          <Box
            sx={{
              ...styles.headerContentContainer,
              ...(headerTitle
                ? {}
                : isFilterRequired
                ? { flex: 1, justifyContent: "space-between" }
                : { flex: 1, justifyContent: "flex-end" }),
            }}
          >
            {!hideSearchBox && (
              <Box sx={styles.search}>
                <Box sx={styles.searchIconWrapper}>
                  <SearchIcon sx={styles.searchIcon} />
                </Box>
                <Box
                  component={InputBase}
                  onChange={(event) => {
                    handleChangeText(event);
                  }}
                  value={search}
                  sx={styles.styledInputBase}
                  placeholder={searchPlaceHolder}
                />
              </Box>
            )}
            {isFilterRequired && (
              <FilterMenu
                filterOptions={filterOptions}
                selectedValue={selectedValue}
                {...(defaultSelected ? { defaultSelected } : {})}
                {...(isSmallFilter ? { isSmallFilter } : {})}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SearchAndFilter.propTypes = {
  defaultSelected: PropTypes.string,
  filterOptions: PropTypes.array,
  handleChangeText: PropTypes.func,
  headerTitle: PropTypes.string,
  isFilterRequired: PropTypes.bool,
  searchPlaceHolder: PropTypes.string,
  search: PropTypes.string,
  isSmallFilter: PropTypes.bool,
  selectedValue: PropTypes.object,
  hideSearchBox: PropTypes.bool,
  customToolbar: PropTypes.object,
  isBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
};

export default SearchAndFilter;

import { useEffect, useRef, useState } from "react";
import axios from "axios";

import NovaAPI from "../../Axios/NovaAPI";
import useAxiosInstance from "../../Axios/hooks/useAxiosInstance";
import { urlService } from "../../utils/urlService";
import { API_STATUS, CANCEL_GET_API_CALL, REC_PER_PAGE } from "../../consts/constants";
import { SUCCESS_STATUS } from "../../api/statusCodes";
import { API_VERSION_NUMBER } from "../apiEndpoints";

/** 
 * 1. useFetch will initiate the API call on component mount automatically so that you don't require to use the useEffect hook in your component explicitly just to call the API.
 * 
 * 2. You can anytime omit this behaviour by passing skipApiCallOnMount: true inside the otherOptions object.
 * 
 * 3. "apiOptions" object is added so that you can pass custom data to the axios get method. For ex- headers.ons
 * 
 * Example to use this hook
 *  const { data } = useFetch({
    url: API_ENDPOINT,
    apiOptions: {
      headers: {
        sampleHeader: "value",
      },
    },
    otherOptions: {
      skipApiCallOnMount: true,
    },
  });
 * */

const useFetch = ({ url, apiOptions, otherOptions,callback }) => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.IDLE);
  const [apiData, setApiData] = useState(null);
  const [error, setError] = useState(null);
  const { axiosInstance } = useAxiosInstance();
  const cancelTokenRef = useRef(null);
  const { callForNonLoggedInUser, skipApiCallOnMount } = otherOptions || {};

  const initiateApiCall = ({ endpoint, otherApiOptions ,newOptions}) => {
    if(newOptions){
      apiOptions=newOptions
    }
    const cancelGetRequest = axios.CancelToken.source();
    cancelTokenRef.current = cancelGetRequest;
    const apiOptionsWithCancelToken = {
      cancelToken: cancelGetRequest.token,
      ...apiOptions,
      ...otherApiOptions,
    };
    if (callForNonLoggedInUser) {
      return NovaAPI.get(`${endpoint}`, apiOptionsWithCancelToken);
    }
    return axiosInstance.get(`${endpoint}`, apiOptionsWithCancelToken);
  };

  const fetchData = async ({
    queryParamsObject,
    otherApiOptions,
    newOptions,
  }) => {
    let modifiedURL = `/${API_VERSION_NUMBER}/${url}`;

    if (otherApiOptions?.headers?.pageNumber) {
      if (isNaN(otherApiOptions?.headers?.pageNumber)) {
        otherApiOptions.headers.pageNumber = 1;
        urlService.setQueryStringValue("pageNumber", 1);
        callback && callback();
      }
    }

    if (otherApiOptions?.headers?.recPerPage) {
      if (isNaN(otherApiOptions?.headers?.recPerPage)) {
        otherApiOptions.headers.recPerPage = REC_PER_PAGE;
        urlService.setQueryStringValue("recPerPage", REC_PER_PAGE);
      }
    }

    if (
      queryParamsObject &&
      urlService.objectToQueryString(queryParamsObject)
    ) {
      modifiedURL += `?${urlService.objectToQueryString(queryParamsObject)}`;
    }
    setApiStatus(API_STATUS.LOADING);
    if (error) setError("");
    return initiateApiCall({
      endpoint: modifiedURL,
      otherApiOptions,
      newOptions,
    })
      .then((res) => {
        if (res.status === SUCCESS_STATUS) {
          const total = res?.headers?.totalcount;
          const totalpages = Math.ceil(total / REC_PER_PAGE);
          const currentPage = urlService.getQueryStringValue("pageNumber") || 1;
          if (
            isNaN(currentPage) ||
            (parseInt(currentPage) > totalpages &&
              !(parseInt(totalpages) === 0 && parseInt(currentPage) === 1))
          ) {
            urlService.setQueryStringValue("pageNumber", 1);
            const newObj = {
              headers: {
                ...apiOptions?.headers,
                ...otherApiOptions?.headers,
                pageNumber: "1",
              },
            };     
            callback && callback();
            return fetchData({ queryParamsObject, otherApiOptions: newObj });
          }
          setApiStatus(API_STATUS.SUCCESS);
          setApiData(res);
          return res.data;
        } else {
          setApiStatus(API_STATUS.ERROR);
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        if (err?.message === CANCEL_GET_API_CALL) return;
        setApiStatus(API_STATUS.ERROR);
        setError(err?.data?.error || "Something went wrong");
      });
  };

  useEffect(() => {
    if (skipApiCallOnMount) {
      return;
    }
    fetchData({});
  }, [url]);

  useEffect(() => {
    return () => {
      cancelTokenRef?.current?.cancel(CANCEL_GET_API_CALL);
    };
  }, []);

  useEffect(() => {
    return () => {
      setApiData(null);
      setApiStatus(API_STATUS.IDLE);
      setError(null);
    };
  }, []);

  const isLoading = apiStatus === API_STATUS.LOADING;
  const isSuccess = apiStatus === API_STATUS.SUCCESS;
  const isError = apiStatus === API_STATUS.ERROR;
  return {
    apiStatus,
    cancelTokenRef,
    data: apiData?.data || null,
    headers: apiData?.headers || null,
    error,
    fetchData,
    isError,
    isLoading,
    isSuccess,
    setData: setApiData,
    setError,
    setApiStatus,
  };
};

export default useFetch;


import React from 'react'
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styles } from './BackButtonStyles';

const BackButton = ({ handleClick }) => {
  return (
    <Box sx={styles.backButtonContainer}>
    <IconButton onClick={handleClick}
    >
      <ArrowBackIcon sx={styles.backButton} />
    </IconButton>
  </Box>
  )
}

export default BackButton

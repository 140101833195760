import {
  APP_PRIMARY_THEME_COLOR,
  APP_THEME_ELEVEN,
  BRIGHT_RED,
  COLORATE,
  DIMGREY,
  DIM_GREY,
  GREEN_TEAL,
  GREY77,
  GREY95,
  GREY_CHATEAU,
  PINKISH_PURPLE,
  TRANSPARENT_GREY,
} from "../../consts/colors";

export const styles = {
  tableHeader: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    color: DIM_GREY,
    textAlign: "left",
  },
  activeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  sucess: {
    backgroundColor: GREEN_TEAL,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  },
  warning: {
    backgroundColor: DIMGREY,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  },
  danger: {
    backgroundColor: BRIGHT_RED,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    padding: "16px 28px 16px, 20px",
    justifyContent: "center",
  },
  actionItems: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  actionIcon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: GREY77,
    "&:hover": {
      color: APP_PRIMARY_THEME_COLOR,
    },
  },
  tableParentContainer: {
    width: "100%",
  },
  tableOuterContainer: {
    width: "100%",
    overflowX: "auto",
    maxHeight: "calc(100vh - 280px)",
    borderRadius: "8px",
    opacity: "0.9",
    border: `1px ${GREY95}`,
  },
  tableContainer: {
    overflowX: "auto",
    maxWidth: "100%",
    overflowY: "auto",
  },
  outerFilterContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "start",
  },
  labelStyle: {
    color: APP_THEME_ELEVEN,
    fontSize: "12px",
    lineHeight: "18px",
  },
  headerFilter: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  arrowUpdward: {
    height: "18px",
    width: "14px",
    cursor: "pointer",
  },
  arrowDownward: {
    height: "18px",
    width: "14px",
    cursor: "pointer",
  },
  evenRow: {
    backgroundColor: TRANSPARENT_GREY,
  },
  pointer: {
    cursor: "pointer",
  },
  largerCellContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  largerText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  imageStyle: {
    width: "70px",
    height: "65px",
  },
  arrow: {
    height: "18px",
    width: "14px",
    color: GREY_CHATEAU,
    fontWeight: "normal",
    cursor: "pointer",
  },
  emptyTableContainer: {
    display: "contents",
    width: "100%",
  },
  greenArrow: {
    color: GREEN_TEAL,
    fontWeight: "600",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: PINKISH_PURPLE,
    },
  },
  tableCell: {
    wordBreak: "break-word",
    textAlign: "left",
  },
  capitalizeText: {
    textTransform: "capitalize",
  },
  imageStyle: {
    width: "70px",
    height: "65px",
  },
  ellipsis: {
    wordBreak: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

import React from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  InputAdornment,
  InputBase,
  IconButton,
} from "@mui/material";
import { styles } from "./CustomInputBaseStyles";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import { commonStyles } from "../../assests/commonStyles";

const CustomInputBase = React.forwardRef((props, ref) => {
  const {
    placeholder,
    value,
    setValue,
    type,
    onClick,
    onChange,
    onKeyPress,
    isRequire,
    showStartIcon,
    startIcon,
    showPasswordVisiblityOffIcon,
    showPasswordVisiblityOnIcon,
    showCheckIcon,
    clearIconHandler,
    iconFunction,
    showClearIcon,
    maxLength,
    validationCheckIconOne,
    validationCheckIconTwo,
    customInputStyle,
    onBlur,
    onFocus,
    onStartIconClick,
    maxChar,
    multiline,
    rows,
    maxRows,
    minRows,
    customClearIconStyles,
    startIconStyles,
    endIconStyles,
    isDisabled,
    name,
    min,
    errorMessage,
    autoFocus,
    readOnly,
    showEndIcon,
    onEndIconClick,
    endIcon,
    autoComplete,
    showLoader,
    endAdornmentCustomStyles,
    isFieldOnPurpleBackground,
    accept
  } = props;

  const changeHandler = (e) => {
    setValue(e.target.value);
  };

  const iconFunctionHandler = () => {
    iconFunction((prev) => !prev);
  };

  const getValue = () => {
    if (isRequire) {
      if (value?.trim()) {
        return value;
      }
      return "";
    }
    return value;
  };

  const getIconStyles = () => {
    if (validationCheckIconOne) {
      return styles.checkEndIcon;
    }
    if (validationCheckIconTwo) {
      return styles.greenIcon;
    }
    return styles.redIcon;
  };

  return (
    <InputBase
      accept={accept}
      inputRef={ref}
      autoFocus={autoFocus}
      name={name ? name : ""}
      disabled={isDisabled}
      inputProps={{ maxLength, min }}
      placeholder={placeholder}
      type={type || "text"}
      value={getValue()}
      readOnly={readOnly}
      multiline={multiline}
      maxRows={maxRows}
      minRows={minRows}
      rows={rows}
      sx={{
        ...styles.inputBase,
        ...customInputStyle,
        ...(errorMessage ? commonStyles.errorInput : {}),
        ...(isFieldOnPurpleBackground ? commonStyles.inputBox : {}),
      }}
      onChange={onChange ? (event) => onChange(event) : changeHandler}
      onFocus={(e) => onFocus && onFocus(e)}
      autoComplete={autoComplete || "off"}
      required={isRequire}
      onBlur={(e) => onBlur && onBlur(e)}
      onClick={onClick ? onClick : () => {}}
      onKeyPress={onKeyPress ? (event) => onKeyPress(event) : () => {}}
      startAdornment={
        showStartIcon && (
          <InputAdornment
            position="start"
            onClick={() => onStartIconClick && onStartIconClick()}
          >
            <IconButton sx={{ ...startIconStyles }} aria-label="aria-label">
              {startIcon}
            </IconButton>
          </InputAdornment>
        )
      }
      endAdornment={
        <InputAdornment position="end">
          {showLoader && (
            <CircularProgress
              thickness={4}
              size={20}
              sx={{ ...commonStyles.loaderOnInputStyles, ...endAdornmentCustomStyles }}
            />
          )}
          {showEndIcon && (
            <InputAdornment
              position="end"
              onClick={() => onEndIconClick && onEndIconClick()}
            >
              <IconButton sx={{ ...endIconStyles }}>{endIcon}</IconButton>
            </InputAdornment>
          )}
          {showPasswordVisiblityOffIcon && (
            <IconButton
              aria-label="toggle password visibility off"
              onClick={iconFunctionHandler}
            >
              <VisibilityOffIcon sx={styles.passwordVisibilityIcon} />
            </IconButton>
          )}
          {showPasswordVisiblityOnIcon && (
            <IconButton
              aria-label="toggle password visibility on"
              onClick={iconFunctionHandler}
            >
              <VisibilityIcon sx={styles.passwordVisibilityIcon} />
            </IconButton>
          )}
          {showClearIcon && (
            <IconButton onClick={clearIconHandler}>
              <ClearIcon
                sx={{ ...customClearIconStyles, ...styles.clearIconStyles }}
              />
            </IconButton>
          )}
          {showCheckIcon && <CheckIcon sx={getIconStyles()} />}
          {maxChar}
        </InputAdornment>
      }
    />
  );
});

CustomInputBase.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  isRequire: PropTypes.bool,
  showStartIcon: PropTypes.bool,
  startIcon: PropTypes.node,
  showPasswordVisiblityOffIcon: PropTypes.bool,
  showPasswordVisiblityOnIcon: PropTypes.bool,
  showCheckIcon: PropTypes.bool,
  clearIconHandler: PropTypes.func,
  iconFunction: PropTypes.func,
  showClearIcon: PropTypes.bool,
  maxLength: PropTypes.string,
  validationCheckIconOne: PropTypes.bool,
  validationCheckIconTwo: PropTypes.bool,
  customInputStyle: PropTypes.object,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onStartIconClick: PropTypes.func,
  maxChar: PropTypes.node,
  multiline: PropTypes.bool,
  rows: PropTypes.string,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  customClearIconStyles: PropTypes.object,
  startIconStyles: PropTypes.object,
  endIconStyles: PropTypes.object,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  showEndIcon: PropTypes.bool,
  onEndIconClick: PropTypes.func,
  endIcon: PropTypes.object,
  autoComplete: PropTypes.string,
  showLoader: PropTypes.bool,
  endAdornmentCustomStyles: PropTypes.object,
  isFieldOnPurpleBackground: PropTypes.bool,
  accept: PropTypes.string,
};

export default CustomInputBase;

import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import Base from "../../Layout/Base";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import CustomBanner from "../../components/CustomBanner/CustomBanner";
import CustomTable from "../../components/CustomTable/CustomTable";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";
import useNavigateScreen from "../../CustomHooks/useNavigateScreen";
import handleSearchWithDebouncing from "../../utils/handleSearchWithDebouncing";
import { getValidRowPerPage } from "../../utils/getValidRowPerPage";
import { handleRowPerPageChange } from "../../utils/handleRowPerPageChange";
import {
  DeleteResultContext,
  types,
} from "../../globalContext/actions/deleteAction";
import { urlService } from "../../utils/urlService";
import { GET_PRACTISES_LIST_FOR_ADMIN } from "../../api/apiEndpoints";
import useFetch from "../../api/common/useFetch";
import navigations from "../../routes/routeNames";
import { practiseColumns } from "./PractiseListConstants";
import {
  SHOW_SAVE_DATA_TOAST_DURATION,
  SHOW_TOAST_MESSAGE_DURATION,
} from "../../consts/toastShowDuration";
import { commonStyles } from "../../assests/commonStyles";
import { styles } from "./PractisesList.styles";

const PractisesList = () => {
  const { navigateScreen } = useNavigateScreen();
  const [currentPage, setCurrentPage] = useState(
    urlService.getQueryStringValue("pageNumber") || 1
  );
  const [searchText, setSearchText] = useState(
    urlService.getQueryStringValue("search") || ""
  );
  const [rowsPerPage, setRowsPerPage] = useState(getValidRowPerPage());
  const [state, dispatch] = useContext(DeleteResultContext);

  const {
    data: practiceData,
    isLoading,
    fetchData,
    error,
    headers,
  } = useFetch({
    url: GET_PRACTISES_LIST_FOR_ADMIN,
    apiOptions: {
      headers: {
        pageNumber: currentPage,
        searchText: searchText,
        Recperpage: rowsPerPage,
        includePagination: true,
      },
    },
    callback:()=>{
      setCurrentPage(1)
    }
  });

  const handlePageChange = (pageNumber) => {
    const totalpages = Math.ceil(headers?.totalcount / rowsPerPage);
    if (pageNumber >= 1 && pageNumber <= totalpages) {
      fetchData({
        otherApiOptions: {
          headers: {
            searchText: searchText,
            pageNumber: pageNumber,
            recPerPage: rowsPerPage,
            includePagination: true,
          },
        },
      });
      setCurrentPage(pageNumber);
      urlService.setQueryStringValue("pageNumber", pageNumber);
    }
  };

  const handleChangeText = (event) => {
    handleSearchWithDebouncing({
      searchText: event.target.value,
      prevSearchText: searchText,
      setSearchText: setSearchText,
      getResult: () =>
        fetchData({
          otherApiOptions: {
            headers: {
              searchText: event.target.value,
              pageNumber: 1,
              recPerPage: rowsPerPage,
              includePagination: true,
            },
          },
        }),
      setCurrentPage: setCurrentPage,
    });
  };

  const handleRowChange = (rowCount) => {
    handleRowPerPageChange({
      rowCount,
      fetchData: () =>
        fetchData({
          otherApiOptions: {
            headers: {
              searchText: searchText,
              pageNumber: 1,
              recPerPage: rowCount,
              includePagination: true,
            },
          },
        }),
      setRowsPerPage,
      setCurrentPage,
    });
  };
  (() => {
    const page = urlService.getQueryStringValue("pageNumber");
    const totalPages = Math.ceil(headers?.totalcount / rowsPerPage);
    if (!page || isLoading) {
      return;
    }
    if (page > totalPages && page != 1 && rowsPerPage != 0) {
      setCurrentPage(1);
      urlService.setQueryStringValue("pageNumber", 1);
    }
  })();

  useEffect(() => {
    const search = urlService.getQueryStringValue("search") || "";
    const page = urlService.getQueryStringValue("pageNumber") || 1;
    if (page) {
      setCurrentPage(+page);
    }
    if (search) {
      setSearchText(search);
    }
  }, []);

  return (
    <Base customStyles={commonStyles.tableContainer}>
      <Box>
        <SearchAndFilter
          headerTitle={"Practices"}
          search={searchText}
          searchPlaceHolder={"Search Practises"}
          handleChangeText={handleChangeText}
        />
        <Box sx={styles.practiseHeader}>
          <CustomButton
            component={"button"}
            classes={styles.commonBtnStyle}
            onClick={() => navigateScreen(navigations.CREATE_PRACTISE)}
          >
            <Typography sx={styles.createButtonText}>
              Create Practice
            </Typography>
          </CustomButton>
        </Box>
        {isLoading && !error ? (
          <CustomLoader customHeight={styles.loaderStyle} />
        ) : error ? (
          <Box sx={commonStyles.errorContainer}>
            <ErrorComponent
              errorHeading={"Error"}
              errorText={error}
              omitCloseButton
            />
          </Box>
        ) : (
          <>
            <CustomTable
              customTableOuterContainer={styles.customTableOuterContainer}
              columns={practiseColumns}
              data={practiceData}
              handleRowClick={(rowData) => {
                navigateScreen(
                  `${navigations.PRACTISES}/${rowData.id}/?mode=view`
                );
              }}
              pagesInfo={{
                currentPage: currentPage,
                total: +headers?.totalcount,
                rowsperPage: rowsPerPage,
                handleRowPerPage: handleRowChange,
              }}
              handlePageChange={handlePageChange}
            />
          </>
        )}

        {state.deleteSuccess == "success" && (
          <Box sx={styles.toastContainer}>
            <CustomBanner
              openSnackbar={
                state.deleteSuccess == "success" &&
                setTimeout(() => {
                  dispatch({ type: types.deleteIdle });
                }, 2000)
              }
              hideDuration={SHOW_TOAST_MESSAGE_DURATION}
              snackbarMessage={"Your data has been deleted sucessfully"}
              severity="success"
              autoHideDuration={SHOW_SAVE_DATA_TOAST_DURATION}
            />
          </Box>
        )}
      </Box>
    </Base>
  );
};

export default PractisesList;

import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";

import CustomBanner from "../../components/CustomBanner/CustomBanner";
import IntroScreen from "../../components/IntroScreen/IntroScreen";
import LoginForm from "../../components/LoginForm/LoginForm";
import { useWindowDimension } from "../../CustomHooks/useWindowDimension";
import {
  DeleteResultContext,
  types,
} from "../../globalContext/actions/deleteAction"
import { COPY_RIGHT_TEXT, PASSWORD_RESET_SUCCESS_MESSAGE } from "../../consts/constants";
import { SHOW_SAVE_DATA_TOAST_DURATION, SHOW_TOAST_MESSAGE_DURATION } from "../../consts/toastShowDuration";
import loginScreenImage from "../../img/loginScreenImage.png";
import { commonStyles } from "../../assests/commonStyles";
import { styles } from "./LoginStyles";

const Login = () => {
  const [width] = useWindowDimension();
  const [state, dispatch] = useContext(DeleteResultContext);
  return (
    <Grid container sx={commonStyles.pageContainer}>
      {width > 900 && (
        <Grid item md={7} lg={9} sx={commonStyles.leftGrid}>
          <IntroScreen backgroundImage={loginScreenImage} />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={5} lg={3} sx={commonStyles.rightGrid}>
        <Box sx={styles.loginParentConatiner}>
          <Box
            sx={{ ...commonStyles.pageContainer, ...styles.loginFormContainer }}
          >
            <LoginForm />
          </Box>
          <Box sx={styles.copyRightsContainer}>
            <Typography sx={styles.copyRightText}>{COPY_RIGHT_TEXT}</Typography>
          </Box>
        </Box>
      </Grid>
      {state.deleteSuccess == "success" && (
          <Box >
            <CustomBanner
              openSnackbar={
                state.deleteSuccess == "success" 
              }
              hideDuration={SHOW_TOAST_MESSAGE_DURATION}
              snackbarMessage={PASSWORD_RESET_SUCCESS_MESSAGE}
              severity="success"
              autoHideDuration={SHOW_SAVE_DATA_TOAST_DURATION}
              onClose={() => dispatch({ type: types.deleteIdle })
            }
            />
          </Box>
        )}
    </Grid>
  );
};

export default Login;

import { useContext, useRef } from "react";
import axios from "axios";

import { UserContext } from "../../globalContext/user/userProvider";
import useAxiosInstance from "../../Axios/hooks/useAxiosInstance";
import useLogoutUser from "../../utils/apiService/Hooks/useLogoutUser";
import {
  setErrorGettingUserInfo,
  setGettingUserData,
  setUserData,
} from "../../globalContext/user/userActions";
import { SOMETHING_WENT_WRONG } from "../../consts/errorMessages";
import { API_VERSION_NUMBER, SESSION } from "../apiEndpoints";

const useGettingUserInfo = () => {
  const [, userDispatch] = useContext(UserContext);
  const cancelTokenRef = useRef(null);
  const { axiosInstance } = useAxiosInstance();
  const { logoutHandler } = useLogoutUser();

  const getUserDetails = async () => {
    try {
      const cancelGetRequest = axios.CancelToken.source();
      cancelTokenRef.current = cancelGetRequest;
      userDispatch(setGettingUserData(true));
      const res = await axiosInstance.get(`${API_VERSION_NUMBER}/${SESSION}`, {
        cancelToken: cancelGetRequest.token,
        headers: {
          platformType: "admin",
        },
      });
      userDispatch(setGettingUserData(false));
      userDispatch(setUserData(res));
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || err.message || SOMETHING_WENT_WRONG;
      if (errorMessage === "Component unmounted") {
        return;
      }
      userDispatch(setErrorGettingUserInfo(errorMessage));
      logoutHandler({});
    } finally {
      userDispatch(setGettingUserData(false));
    }
  };

  return { cancelTokenRef, getUserDetails };
};

export default useGettingUserInfo;

import { GREEN_TEAL } from "../../consts/colors";

export const styles = {
  right: {
    padding: "0px 14px",
    flex: 1,
    position: "relative",
  },
  loaderContainer: {
    width: "100%",
    height: "calc(100vh - 200px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderStyles: {
    height: "50vh",
  },
  errorOuter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 200px)",
  },
  customErrorContainerStyles: {
    display: "flex",
    alignItems: "center",
  },
  snackBarStyle: {
    background: GREEN_TEAL,
    display: "block",
    textAlign: "center",
    fontSize: "16px",
    borderRadius: "5px",
  },
  full: {
    width: "100%",
  },
};

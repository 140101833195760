import axios from "axios";

import useLogoutUser from "../../utils/apiService/Hooks/useLogoutUser";
import getToken from "../../auth/getToken";
import { config } from "../../consts/constants";
import { SESSION_EXPIRED_MESSAGE } from "../../consts/errorMessages";
import { UNAUTHORIZED_USER } from "../../api/statusCodes";

const useAxiosInstance = () => {
  const { logoutHandler } = useLogoutUser();

  const axiosInstance = axios.create({
    baseURL: config.url.API_URL,
  });

  axiosInstance.interceptors.request.use((request) => {
    request.headers["Content-Type"] = "application/json";
    request.headers["x-auth-token"] = getToken();
    return request;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.status === UNAUTHORIZED_USER) {
        logoutHandler({
          logoutDetails: {
            message: SESSION_EXPIRED_MESSAGE,
            state: "error",
          },
        });
      }
      return response;
    },
    (error) => {
      if (error.response?.status === UNAUTHORIZED_USER) {
        logoutHandler({
          logoutDetails: {
            message: SESSION_EXPIRED_MESSAGE,
            state: "error",
          },
        });
      }
      return Promise.reject(error);
    }
  );

  return { axiosInstance };
};

export default useAxiosInstance;

import { RED_RATING } from "../../consts/colors";

export const styles = {
  logContainer: {
    padding: "10px  50px 50px 50px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "50vh",
    minWidth:"30vw",
    minHeight:"20vh"
  },
  logHeader: { color: RED_RATING, margin: "20px 0px", fontWeight: "600" },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  content:{
display:"flex",
flexDirection:"column",
gap:"10px"
  }
};

// API VERSION NUMBER
export const API_VERSION_NUMBER = "v4";

/** API Endpoints */
export const ARCHIVE_REVIEW = "archiveFADReview";
export const GET_ADMIN_LIST = "admin";
export const GET_APPOINTMENT_LIST_FOR_ADMIN = "getFadAppointmentList";
export const GET_DECLINED_OFFICE_PAYMENTS_FOR_ADMIN = "officeDeclinedPayments";
export const GET_PATIENT_LIST_FOR_ADMIN = "getFadPatientsList";
export const GET_PROVIDER_LIST_FOR_ADMIN = "getProvidersListForAdmin";
export const GET_REVIEWS_LIST_FOR_ADMIN = "getAllFADReviews";
export const GET_SAAS_ADMIN_LIST = "saasCarePulseAdmin";
export const GET_SAAS_ADMIN_PAYMENT_SUMMARY_LIST =
  "saasCarePulseAdminPaymentSummary";
export const GET_SASS_PAYMENT = "getCarePulseSaasAdminPayment";
export const GET_UPLOAD_URL = "signature";
export const GET_REVENUE_LIST_FOR_ADMIN = "getTeledentalPaymentsDetails";
export const GET_USER_LIST = "admin";
export const UPDATE_USER = "updateCarePulseAdminUser";
export const CREATE_USERS = "ext1/carePulseAdminUsers";
export const GET_OFFICE_LIST = "office?practiceId";
export const GET_PRACTISES_LIST_FOR_ADMIN = "practices";
export const OFFICE_URL = "office";
export const SESSION = "session";
export const UNARCHIVE_REVIEW = "unarchiveFADReview";
export const UPDATE_OFFICE_DETAILS = "office";
export const GET_NET_DETAILS = "getCarePulseAnetDetails";
export const DELETE_CARD_DETAILS = "deleteOfficePaymentCredentials";
export const UPDATE_SASS_PAYMENT = "updateSaasCarePulseAdminPayment";
export const RESET_PASSWORD_URL = "validateCarePulseAdminToken";

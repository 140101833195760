import React, { useContext } from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";

import CustomLoader from "../CustomLoader/CustomLoader";
import useNavigateScreen from "../../CustomHooks/useNavigateScreen";
import useLogoutUser from "../../utils/apiService/Hooks/useLogoutUser";
import { UserContext } from "../../globalContext/user/userProvider";
import navigations from "../../routes/routeNames";
import logo from "../../img/logo.svg";
import logoutImage from "../../img/logout.svg";
import { styles } from "./Header.styles";

const Header = () => {
  const { navigateScreen } = useNavigateScreen();
  const { isLoggingUserOut, logoutHandler } = useLogoutUser();
  const [userState] = useContext(UserContext);
  const userInfo = userState?.userInfo?.data?.CarePulseAdmin;

  return (
    <AppBar position="sticky" elevation={0} sx={styles.appbarStyle}>
      <Toolbar sx={styles.toolBarStyle}>
        <Typography
          onClick={() => navigateScreen(navigations.PRACTISES)}
          component={"img"}
          src={logo}
          alt="App Logo"
          style={styles.imagelogo}
        />
        <Box sx={styles.rightContainer}>
          {!!userInfo && Object.keys(userInfo)?.length && (
            <Box sx={styles.userInfoContainer}>
              <Box sx={styles.userInfo}>
                <Typography sx={styles.welcomeText}>
                  Hi, {userInfo?.name || ""}
                </Typography>
                <Typography sx={styles.userType}>
                  {userInfo?.role || "Admin"}
                </Typography>
              </Box>
            </Box>
          )}
          {isLoggingUserOut ? (
            <Box>
              <CustomLoader
                customHeight={styles.loaderStyles}
                loaderIconStyles={styles.loaderIconStyles}
              />
            </Box>
          ) : (
            <Box sx={styles.logoutContainer}>
              <Typography
                component={"img"}
                src={logoutImage}
                sx={styles.logoutBtn}
                alt={"Logout Icon"}
                onClick={() => logoutHandler({})}
              />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;

import defaultImage from "../../img/defaultImage.jpeg";

export const practiseColumns = [
  {
    id: 1,
    value: "logo",
    label: "Logo",
    maxWidth: "300px",
    minWidth:"150px",
    isDataTypeImage: true,
    defaultImage: defaultImage,
  },

  {
    id: 2,
    value: "name",
    label: "Practice Name",
    minWidth: "150px",
    isTextCapitalize:true
  },
  {
    id: 3,
    value: "address",
    label: "Practice Address",
    minWidth: "150px",
    isTextCapitalize:true
  },
  {
    id: 4,
    value: "suite",
    label: "Suite #",
    minWidth: "180px",
  },

  {
    id: 5,
    value: "state",
    label: "State",
    maxWidth: "180px",
    minWidth: "120px",
    isTextCapitalize:true
  },

  {
    id: 6,
    value: "city",
    label: "City",
    maxWidth: "180px",
    minWidth: "120px",
    isTextCapitalize:true
  },

  {
    id: 7,
    value: "country",
    label: "Country",
    maxWidth: "180px",
    minWidth: "120px",
    isTextCapitalize:true
  },
  {
    id: 8,
    value: "phone",
    label: "Office Phone",
    minWidth: "150px",
  },
  {
    id: 9,
    value: "websiteAddress",
    label: "Website Address",
    minWidth: "150px",
  },
  {
    id: 10,
    value: "twilioPhoneNumber",
    label: "Twilio Phone Number",
    minWidth: "120px",
  },
];

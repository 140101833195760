export const columns = [
  {
    id: 1,
    value: "title",
    label: "Provider Title",
    isSortable: true,
    minWidth: "120px",
  },
  {
    id: 2,
    value: "firstName",
    label: "Provider First Name",
    minWidth: "150px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 3,
    value: "lastName",
    label: "Provider Last Name",
    minWidth: "150px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 4,
    value: "role",
    label: "Speciality",
    minWidth: "150px",
    maxWidth: "180px",
    isSortable: true,
    isEllipsis: true,
  },
  {
    id: 5,
    value: "providerCellNumber",
    label: "Provider Cell Number",
    minWidth: "150px",
    isSortable: false,
    noWordWrap: true,
  },
  {
    id: 6,
    value: "practiceName",
    label: "Practice Name",
    minWidth: "200px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 7,
    value: "practicePhoneNumber",
    label: "Practice Phone Number",
    minWidth: "150px",
    isSortable: false,
    noWordWrap: true,
  },
  {
    id: 8,
    value: "address",
    label: "Street Address",
    minWidth: "200px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 9,
    value: "suite",
    label: "Suite",
    minWidth: "100px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 10,
    value: "city",
    label: "City",
    minWidth: "150px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 11,
    value: "state",
    label: "State",
    minWidth: "150px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  { id: 12, value: "zip", label: "Zip", minWidth: "150px", isSortable: true },
  {
    id: 13,
    value: "country",
    label: "Country",
    minWidth: "150px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 14,
    value: "activeStatus",
    label: "Status",
    isSortable: true,
    isDot: false,
  },
];

export const DEFAULTSORT = "PROVIDER_FIRST_NAME_ASC";
export const DEFAULTSTATUS = "All";

export const appointmentListColumns = [
  {
    id: 1,
    value: "appointmentDate",
    label: "Appointment Date",
    isSortable: true,
    isDataTypeDate: true,
    maxWidth: "150px",
  },
  {
    id: 2,
    value: "startTime, endTime, timeZone",
    label: "Appointment Time",
    maxWidth: "150px",
    sortValue: "appointmentTime",
    isSortable: true,
    isCombinedColumn: true,
    formatter: (data) => {
      const startTime = data.startTime || "--";
      const endTime = data.endTime || "--";
      const timeZone = data.timeZone
        ? "(" + data.timeZone.split(" ")[0] + ")"
        : "";
      return `${startTime} - ${endTime} ${timeZone}`.trim();
    },
  },
  {
    id: 3,
    value: "patientName",
    label: "Patient Name",
    maxWidth: "180px",
    isSortable: true,
  },
  {
    id: 4,
    value: "providerName",
    label: "Provider Name",
    maxWidth: "180px",
    isSortable: true,
  },
  {
    id: 5,
    value: "officeName",
    label: "Office Name",
    maxWidth: "180px",
    isSortable: true,
  },
  {
    id: 6,
    value: "appointmentType",
    label: "Appointment Type",
    maxWidth: "150px",
    isSortable: true,
  },
  {
    id: 7,
    value: "consultationFee",
    label: "Fee Paid",
    maxWidth: "120px",
    isDataTypeAmount: true,
    isSortable: false,
  },
  {
    id: 8,
    value: "fadPatientNotes",
    label: "Patient Appointment Notes",
    maxWidth: "240px",
    displayEyeIcon: true,
    isSortable: false,
  },
  {
    id: 9,
    value: "appointmentStatus",
    label: "Appointment Status",
    maxWidth: "150px",
    isSortable: true,
  },
];

export const appointmentTableFilterMap = {
  APPOINTMENT_DATE: "appointmentDate",
  APPOINTMENT_TIME: "appointmentTime",
  PATIENT_NAME: "patientName",
  PROVIDER_NAME: "providerName",
  OFFICE_NAME: "officeName",
  APPOINTMENT_TYPE: "appointmentType",
  APPOINTMENT_STATUS: "appointmentStatus",
};
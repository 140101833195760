import appointmentIcon from "../../img/appointmentIcon.svg";
import revenueImage from "../../img/revenueImage.svg";
import reviewImage from "../../img/reviewImage.svg";
import providerImage from "../../img/providerImage.svg";
import patientImage from "../../img/patientImage.svg";
import PracticeImage from "../../img/practiceImage.svg";
import saasIcon from "../../img/saasIcon.svg";
import userIcon from "../../img/userIcon.svg";
import navigations from "../../routes/routeNames";

export const sideBarIcons = [
  {
    img: PracticeImage,
    navigateTo: navigations.PRACTISES,
    name: "Practices",
    id: 1,
    displaySeparator: true,
  },
  {
    img: saasIcon,
    navigateTo: navigations.SAAS,
    name: "SaaS",
    id: 2,
    displaySeparator: true,
  },
  {
    img: providerImage,
    navigateTo: navigations.PROVIDERS,
    name: "Providers",
    id: 3,
  },
  {
    img: patientImage,
    navigateTo: navigations.PATIENTS,
    name: "Patients",
    id: 4,
  },
  {
    img: appointmentIcon,
    navigateTo: navigations.APPOINTMENTS,
    name: "Appointments",
    id: 5,
  },
  {
    img: reviewImage,
    navigateTo: navigations.REVIEWS,
    name: "Reviews",
    id: 6,
  },
  {
    img: revenueImage,
    navigateTo: navigations.REVENUE,
    name: "Revenue",
    id: 7,
    displaySeparator: true,
  },
  {
    img: userIcon,
    navigateTo: navigations.USERS,
    name: "Users",
    id: 8,
  },
];

import { urlService } from "./urlService";

export const getCurrentSortFilter = ({
  sortBy,
  currentSortType,
  defaultSortKey,
  filterTableMap,
}) => {
  const sortOrder = !currentSortType
    ? sortBy.includes("DESC")
      ? -1
      : 1
    : sortBy;
  let sortKey;
  if (!currentSortType) {
    const sortField = sortBy.split("_").slice(0, -1).join("_");
    const sortFieldKey = Object.keys(filterTableMap).find(
      (key) => key === sortField
    );
    sortKey = sortFieldKey ? filterTableMap[sortFieldKey] : defaultSortKey;
  } else {
    sortKey = Object.values(filterTableMap).includes(sortBy)
      ? sortBy
      : defaultSortKey;
  }
  const result = { key: sortKey, type: sortOrder };
  if (currentSortType) {
    const sortFieldKey = Object.keys(filterTableMap).find(
      (key) => filterTableMap[key] === sortKey
    );
    if (sortFieldKey) {
      result.sortField = `${sortFieldKey}_${currentSortType}`;
    }
  }
  return result;
};
export const updateSortFilter = ({
  sortingFilter,
  currentSortType,
  defaultSortKey,
  filterTableMap,
  fetchListFunction,
  searchText,
  currentPage,
  recPerPage,
  setSortBy,
  setSorting,
  setCurrentPage,
  resetSortBy,
  hideQueryParams,
}) => {
  setCurrentPage(1);
  let sortByFilter = "";
  if (!resetSortBy) {
    sortByFilter = getCurrentSortFilter({
      sortBy: sortingFilter,
      currentSortType: currentSortType,
      defaultSortKey: defaultSortKey,
      filterTableMap: filterTableMap,
    })?.sortField;
    setSorting({
      type: currentSortType === "ASC" ? 1 : -1,
      key: sortingFilter,
    });
    setSortBy(sortByFilter);
    if (!hideQueryParams) {
      urlService.setQueryStringValue("sortBy", sortByFilter);
    }
  } else {
    setSortBy("");
    setSorting({ type: "", key: 0 });

    if (!hideQueryParams) urlService.removeParam("sortBy");
  }
  if (!hideQueryParams) {
    urlService.setQueryStringValue("pageNumber", 1);
  }
  fetchListFunction({
    queryParamsObject: resetSortBy ? {} : { sortBy: sortByFilter },
    otherApiOptions: {
      headers: {
        searchText: searchText,
        pageNumber: currentPage,
        recPerPage: recPerPage,
        includePagination: true,
      },
    },
  });
};

import React from "react";
import { Redirect } from "react-router-dom";

import getToken from "../../auth/getToken";
import navigations from "../routeNames";

const DefaultRoute = () => {
  const tokenCheck = getToken();
  if (tokenCheck) {
    return <Redirect to={navigations.PRACTISES} />;
  }
  return <Redirect to={navigations.LOGIN} />;
};

export default DefaultRoute;

import { useEffect, useState, useContext } from "react";

import NovaAPI from "../../../Axios/NovaAPI";
import history from "../../../history";
import navigations from "../../../routes/routeNames";
import { AuthContext } from "../../../globalContext/auth/authProvider";
import { setAuth } from "../../../globalContext/auth/authActions";
import { API_STATUS } from "../../../consts/constants";
import { DATA_SAVED_SUCCESSFULLY } from "../../../api/statusCodes";
import {
  GENERIC_GET_API_FAILED_ERROR_MESSAGE,
  INVALID_CREDENTIALS,
  NOT_AUTHORISED,
  loginErrorMessages,
} from "../../../consts/errorMessages";
const useLoginUser = () => {
  const [, authDispatch] = useContext(AuthContext);
  const [loginStatus, setLoginStatus] = useState(API_STATUS.IDLE);
  const [loginUserResult, setLoginUserResult] = useState([]);
  const [errorWhileLoggingIn, setErrorWhileLoggingIn] = useState("");
  const handleUserLogin = async (payload) => {
    try {
      setLoginStatus(API_STATUS.LOADING);
      errorWhileLoggingIn && setErrorWhileLoggingIn("");
      const res = await NovaAPI.put(`v4/session`, payload);
      if (res.status === DATA_SAVED_SUCCESSFULLY) {
        const authToken = res.data.token;
        setLoginStatus(API_STATUS.SUCCESS);
        setLoginUserResult(res.data);
        authDispatch(setAuth(authToken));
        history.push(navigations.PRACTISES);
        return;
      }
      setLoginStatus(API_STATUS.ERROR);
      setErrorWhileLoggingIn(GENERIC_GET_API_FAILED_ERROR_MESSAGE);
    } catch (err) {
      setLoginStatus(API_STATUS.ERROR);
      if (err.response?.data?.message) {
        if (
          err.response?.data?.message === loginErrorMessages.WRONG_CREDENTIALS
        ) {
          setErrorWhileLoggingIn(INVALID_CREDENTIALS);
          return;
        }
        if (err.response?.data?.message === loginErrorMessages.NOT_AUTHORIZED) {
          setErrorWhileLoggingIn(NOT_AUTHORISED);
          return;
        }
        setErrorWhileLoggingIn(err.response?.data?.message);
        return;
      }
      setErrorWhileLoggingIn(GENERIC_GET_API_FAILED_ERROR_MESSAGE);
    }
  };

  const isLoggingIn = loginStatus === API_STATUS.LOADING;
  const isSuccessfullyLoggedIn = loginStatus === API_STATUS.SUCCESS;
  const errorLogginIn = loginStatus === API_STATUS.ERROR;

  useEffect(() => {
    return () => {
      setLoginStatus(API_STATUS.IDLE);
      setLoginUserResult([]);
      setErrorWhileLoggingIn("");
    };
  }, []);

  return {
    errorLogginIn,
    errorWhileLoggingIn,
    handleUserLogin,
    isLoggingIn,
    isSuccessfullyLoggedIn,
    loginStatus,
    loginUserResult,
    setErrorWhileLoggingIn,
  };
};

export default useLoginUser;

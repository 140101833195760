import { removeAll, setItem } from "../../services/localStorageService";
import { AUTH_KEY } from "../../consts/constants";
import * as types from "./types";

export const setAuth = (payload) => {
  setItem(AUTH_KEY, payload);
  return {
    type: types.SET_AUTH,
    payload,
  };
};

export const clearAuth = () => {
  removeAll();
  return {
    type: types.CLEAR_AUTH,
  };
};

export const setLogoutInfo = (payload) => {
  return {
    type: types.SET_LOGOUT_INFO,
    payload,
  };
};
